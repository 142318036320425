import React from 'react';
import { Form } from 'react-bootstrap';

const FormCheckboxSelect = ({
  label,
  name,
  value = [],
  options = [],
  changeHandler,
  hasError,
}) => {
  const handleAdd = (val) => {
    let nextItems = [...value];

    if (nextItems.includes(val)) {
      nextItems = nextItems.filter((item) => item !== val);
    } else {
      nextItems = [...nextItems, val];
    }

    changeHandler(name, nextItems);
  };

  return (
    <Form.Group className="mb-4">
      <Form.Label>{label}</Form.Label>

      <div>
        {options.map((option) => (
          <Form.Check
            key={option}
            inline
            label={option}
            name={name}
            type="checkbox"
            checked={value.includes(option)}
            onChange={() => handleAdd(option)}
            id={`${name}-${option}`}
            isInvalid={Boolean(hasError)}
          />
        ))}
        <Form.Control.Feedback
          type="invalid"
          style={{ display: hasError ? 'block' : 'none' }}
        >
          {hasError}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export default FormCheckboxSelect;
