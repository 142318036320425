import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { htmlDecode } from '../../helpers/helpers';

const FormTextarea = ({ label, name, inputId, value, rows, changeHandler }) => {
  const [height, setHeight] = useState('inherit');

  const textRef = useRef();

  const onChangeHandler = (e) => {
    setHeight('auto');
    changeHandler(e);
  };

  useEffect(() => {
    setHeight(`${textRef.current.scrollHeight}px`);
  }, [value]);

  return (
    <Form.Group className="mb-4" controlId={inputId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={rows}
        onChange={onChangeHandler}
        value={htmlDecode(value)}
        name={name}
        style={{ height }}
        ref={textRef}
      />
    </Form.Group>
  );
};

export default FormTextarea;
