import UserService from '../services/userService';

import {
  USER_FETCH_ALL,
  USER_FETCH_ALL_FLAT,
  USER_FETCH_ONE,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  USER_SET_ERRORS,
  USER_CLEAR_STATE,
} from './actionTypes';

export const userFetchAll = (query) => (dispatch) => {
  UserService.getAll(query)
    .then(({ data }) =>
      dispatch({
        type: USER_FETCH_ALL,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: USER_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const userFetchAllFlat = () => (dispatch) => {
  UserService.getAllFlat()
    .then(({ data }) =>
      dispatch({
        type: USER_FETCH_ALL_FLAT,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: USER_SET_ERRORS,
        payload: response,
      }),
    );
};

export const userFetchOne = (id) => (dispatch) => {
  UserService.getOne(id)
    .then(({ data }) =>
      dispatch({
        type: USER_FETCH_ONE,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: USER_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const userCreate = (data, onSuccess) => (dispatch) => {
  UserService.create(data)
    .then((res) => {
      dispatch({
        type: USER_CREATE,
        payload: res.data,
      });

      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) =>
      dispatch({
        type: USER_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const userUpdate = (id, data, onSuccess) => (dispatch) => {
  UserService.update(id, data)
    .then((res) => {
      dispatch({
        type: USER_UPDATE,
        payload: res.data,
      });

      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) =>
      dispatch({
        type: USER_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const userDeleteOne = (id, onSuccess) => (dispatch) => {
  UserService.remove(id)
    .then(({ data }) => {
      dispatch({
        type: USER_DELETE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch((err) =>
      dispatch({
        type: USER_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const userClearState = () => (dispatch) => {
  dispatch({
    type: USER_CLEAR_STATE,
  });
};
