import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const FormCustomSelect = ({
  label,
  name,
  inputId,
  defaultValue,
  options = [],
  changeHandler,
  hasError,
}) => {
  const [key, setKey] = useState();

  useEffect(() => {
    if (options.length) {
      setKey(options.length);
    }
  }, [options]);

  const handleChange = (e) => {
    const nextSelected = e.reduce((acc, i) => {
      acc.push(i.value);
      return acc;
    }, []);

    changeHandler(name, nextSelected);
  };

  return (
    <Form.Group className="mb-4" controlId={inputId}>
      <Form.Label>{label}</Form.Label>

      <Select
        key={key}
        defaultValue={options.filter((o) => defaultValue?.includes(o.value))}
        isMulti
        options={options}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="invalid">{hasError}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormCustomSelect;
