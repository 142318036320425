import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, CloseButton } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import styles from './FormLogo.module.scss';

const FormLogo = ({ label, name, defaultValue, changeHandler }) => {
  const [preview, setPreview] = useState();

  const inputRef = useRef();

  const handleAddNew = (e) => {
    const { files } = e.target;
    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    reader.readAsDataURL(file);

    changeHandler(name, files[0]);
  };

  const handleRemove = () => {
    inputRef.current.value = '';
    setPreview(null);
    changeHandler(name, '');
  };

  useEffect(() => {
    if (!defaultValue) return;

    setPreview(`${process.env.REACT_APP_API_PUBLIC}${defaultValue}`);
  }, [defaultValue]);

  return (
    <div className="mb-4">
      {label && <Form.Label>{label}</Form.Label>}

      <div className={styles.wrapper}>
        {preview && (
          <CloseButton
            className="position-absolute top-0 end-0 mt-2 me-2"
            type="button"
            onClick={handleRemove}
          />
        )}

        <label className={styles.label}>
          {preview ? (
            <img src={preview} alt="logo" className={styles.preview} />
          ) : (
            <Icon.Upload size={32} />
          )}

          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            name="logo"
            className={styles.input}
            onChange={handleAddNew}
          />
        </label>
      </div>
    </div>
  );
};

FormLogo.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  changeHandler: PropTypes.func,
};

FormLogo.defaultProps = {
  label: '',
  name: '',
  defaultValue: '',
  changeHandler: () => {},
};

export default FormLogo;
