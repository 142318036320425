import React from 'react';
import { Form } from 'react-bootstrap';
import { htmlDecode } from '../../helpers/helpers';

const FormSelect = ({
  label,
  type,
  name,
  inputId,
  value,
  options,
  changeHandler,
  disabled,
  hasError,
}) => (
  <Form.Group className="mb-3" controlId={inputId}>
    <Form.Label>{label}</Form.Label>
    <Form.Select
      type={type}
      onChange={changeHandler}
      value={htmlDecode(value || '')}
      name={name}
      isInvalid={Boolean(hasError)}
      disabled={Boolean(disabled)}
    >
      <option value="">Choose one...</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {htmlDecode(option.label || option.value)}
        </option>
      ))}
    </Form.Select>
    <Form.Control.Feedback type="invalid">{hasError}</Form.Control.Feedback>
  </Form.Group>
);

export default FormSelect;
