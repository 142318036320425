import IntakeService from '../services/intakeService';

import {
  INTAKE_FETCH_ALL,
  INTAKE_FETCH_ALL_FLAT,
  INTAKE_FETCH_ONE,
  INTAKE_CREATE,
  INTAKE_UPDATE,
  INTAKE_DELETE,
  INTAKE_SET_ERRORS,
  INTAKE_CLEAR_STATE,
} from './actionTypes';

export const intakeFetchAll = (query) => (dispatch) => {
  IntakeService.getAll(query)
    .then(({ data }) =>
      dispatch({
        type: INTAKE_FETCH_ALL,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: INTAKE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const intakeFetchAllFlat = () => (dispatch) => {
  IntakeService.getAllFlat()
    .then(({ data }) =>
      dispatch({
        type: INTAKE_FETCH_ALL_FLAT,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: INTAKE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const intakeFetchOne = (id) => (dispatch) => {
  IntakeService.getOne(id)
    .then(({ data }) =>
      dispatch({
        type: INTAKE_FETCH_ONE,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: INTAKE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const intakeCreate = (newData, onSuccess) => (dispatch) => {
  IntakeService.create(newData)
    .then(({ data }) => {
      dispatch({
        type: INTAKE_CREATE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: INTAKE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const intakeUpdate = (id, newData, onSuccess) => (dispatch) => {
  IntakeService.update(id, newData)
    .then(({ data }) => {
      dispatch({
        type: INTAKE_UPDATE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: INTAKE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const intakeDeleteOne = (id, onSuccess) => (dispatch) => {
  IntakeService.remove(id)
    .then(({ data }) => {
      dispatch({
        type: INTAKE_DELETE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: INTAKE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const intakeClearState = () => (dispatch) => {
  dispatch({
    type: INTAKE_CLEAR_STATE,
  });
};
