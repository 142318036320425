import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({ component: Component, auth, location, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated,
  );
  const { from } = location.state || { from: { pathname: '/' } };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Route
      {...rest}
      render={({ ...props }) =>
        isAuthenticated ? <Redirect to={from} /> : <Component {...props} />
      }
    />
  );
};

export default AuthRoute;
