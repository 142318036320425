import { UI_IS_LOADING, UI_SET_TOAST } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  toast: null,
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case UI_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case UI_SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      };

    default:
      return state;
  }
};

export default ui;
