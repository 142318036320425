import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Col, Row, Card, Form, Button } from 'react-bootstrap';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { locationFetchAll } from '../actions/locationActions';
import Layout from '../components/Layout';
import PaginationBlock from '../components/PaginationBlock';
import useQuery from '../hooks/useQuery';
import { Routes } from '../json/routes';
import ListTopBar from '../components/ListTopBar';
import useForm from '../hooks/useForm';
import { htmlDecode } from '../helpers/helpers';

const LocationPage = () => {
  const dispatch = useDispatch();

  const { locationList } = useSelector((state) => state.locationReducer);

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const title = query.get('title');
  const sort = query.get('sort');
  const page = query.get('page');

  const [isLoading, setIsLoading] = useState(true);
  const { values, handleStateChange, handleInputChange } = useForm({
    title: title || '',
    sort: sort || 'createdAt',
    page: page || 1,
  });

  const prepareQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    const params = {
      title: nextData.title,
      sort: nextData.sort,
      page: nextData.page,
    };

    return params;
  };

  const prepareSearchQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    nextData.title ? query.set('title', nextData.title) : query.delete('title');
    nextData.sort ? query.set('sort', nextData.sort) : query.delete('sort');
    nextData.page ? query.set('page', nextData.page) : query.delete('page');

    const searchParams = query.toString();

    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    });
  };

  const handlePage = (p) => {
    setIsLoading(true);

    handleStateChange('page', p);

    const params = prepareQuery(values, { page: p });

    dispatch(locationFetchAll(params));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setIsLoading(true);

    prepareSearchQuery(values, { page: 1 });

    const params = prepareQuery(values, { page: 1 });

    dispatch(locationFetchAll(params));
  };

  useEffect(() => {
    const params = {
      ...(title && { title }),
      ...(sort && { sort }),
      ...(page && { page }),
    };

    dispatch(locationFetchAll(params));
  }, [dispatch, title, page, sort]);

  useEffect(() => {
    if (locationList?.items) setIsLoading(false);
  }, [locationList]);

  return (
    <Layout>
      <ListTopBar
        title="Locations"
        newItemLink={Routes.Location.new}
        total={locationList.total}
      />
      <ListSearchbar
        values={values}
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
      />

      {isLoading ? (
        'Loading...'
      ) : (
        <ListBlock locationList={locationList} handlePage={handlePage} />
      )}
    </Layout>
  );
};

export default LocationPage;

const ListSearchbar = ({ handleSearch, values, handleInputChange }) => (
  <Card body className="mt-3">
    <form onSubmit={handleSearch}>
      <Row>
        <Form.Group as={Col} md={3}>
          <Form.Label>Title</Form.Label>
          <Form.Control
            value={values.title}
            name="title"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Order by</Form.Label>

          <Form.Select
            value={values.sort}
            name="sort"
            onChange={handleInputChange}
          >
            <option value="createdAt">Created</option>
            <option value="title">Title</option>
          </Form.Select>
        </Form.Group>

        <Col md="auto" className="mt-auto">
          <Button variant="primary" onClick={handleSearch} type="submit">
            Filter
          </Button>
        </Col>
      </Row>
    </form>
  </Card>
);

const ListBlock = ({ locationList, handlePage }) => (
  <>
    <ListGroup className="mt-3">
      {locationList.items?.map((item) => (
        <ListGroup.Item
          key={item.id}
          as={NavLink}
          to={`${Routes.Location.item}${item.id}`}
          action
        >
          <div className="d-flex align-items-center">
            <span className="fs-5 fw-bold text-primary">
              {htmlDecode(item.title)}
            </span>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>

    <PaginationBlock
      pageHandler={handlePage}
      currentPage={locationList.page}
      total={locationList.total}
    />
  </>
);
