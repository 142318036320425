import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Alert, Card, Button, Form, Spinner } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import * as Icon from 'react-bootstrap-icons';
import Layout from '../components/Layout';
import {
  userFetchOne,
  userCreate,
  userUpdate,
  userDeleteOne,
  userClearState,
} from '../actions/userActions';
import { setToast } from '../actions/uiActions';
import useForm from '../hooks/useForm';
import FormInput from '../components/FormInput';
import FormSelect from '../components/FormSelect';
import { Routes } from '../json/routes';

const UserPage = ({ match }) => {
  const { id } = match.params;

  return (
    <Layout>
      <div className="py-3">
        <Button variant="link" as={NavLink} to={Routes.User.list}>
          <Icon.ChevronLeft className="me-1" />
          Back to User list
        </Button>
      </div>

      <FromContainer id={id} />
    </Layout>
  );
};

export default UserPage;

const initialFieldValues = {
  name: '',
  email: '',
  role: 'USER',
  password: '',
  repassword: '',
};

const FromContainer = ({ id }) => {
  const isNew = !id;
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state.authReducer.user.id);
  const { role } = useSelector((state) => state.authReducer.user);
  const { user, errors } = useSelector((state) => state.userReducer);
  const { values, setValues, handleInputChange } = useForm(initialFieldValues);
  const [isLoading, setIsLoading] = useState(!isNew);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inputErrors, setInputErrors] = useState();

  const onUpdateSuccess = () => {
    setIsSubmitting(false);
    setValues((initState) => ({ ...initState, password: '', repassword: '' }));

    dispatch(
      setToast({
        status: 'success',
        title: 'User is updated!',
      }),
    );
  };

  const onCreateSuccess = (newUser) => {
    setIsSubmitting(false);
    setValues((initState) => ({ ...initState, password: '', repassword: '' }));

    dispatch(
      setToast({
        status: 'success',
        title: 'User is created!',
      }),
    );

    if (newUser.id) history.push(`${Routes.User.item}${newUser.id}`);
  };

  const onDeleteSuccess = () => {
    setIsDeleting(false);

    dispatch(
      setToast({
        status: 'success',
        title: 'User is deleted!',
      }),
    );

    history.push(Routes.User.list);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    if (values.password && values.password !== values.repassword) {
      setInputErrors((initState) => ({
        ...initState,
        password: {
          msg: 'Passwords should match',
        },
        repassword: {
          msg: 'Passwords should match',
        },
      }));

      return;
    }

    setIsSubmitting(true);
    setInputErrors(null);

    dispatch(userCreate(values, onCreateSuccess));
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (values.password && values.password !== values.repassword) {
      setInputErrors((initState) => ({
        ...initState,
        password: {
          msg: 'Passwords should match',
        },
        repassword: {
          msg: 'Passwords should match',
        },
      }));

      return;
    }

    setIsSubmitting(true);
    setInputErrors(null);

    dispatch(userUpdate(id, values, onUpdateSuccess));
  };

  const handleDelete = () => {
    setIsDeleting(true);

    if (window.confirm('Are you sure you want to delete this User?'))
      dispatch(userDeleteOne(id, onDeleteSuccess));
  };

  useEffect(() => {
    setValues((initState) => ({ ...initState, ...user }));
  }, [user, setValues]);

  useEffect(() => {
    if (id) dispatch(userFetchOne(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id && user.id && id === user.id) setIsLoading(false);
  }, [id, user]);

  useEffect(() => {
    if (!errors) return;

    const nextInputErrors = errors.errors?.reduce((acc, err) => {
      acc[err.param] = err;
      return acc;
    }, {});

    setInputErrors(nextInputErrors);
    setIsLoading(false);
    setIsSubmitting(false);
    setIsDeleting(false);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(userClearState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!inputErrors) return;

    const nextInputErrors = [];

    Object.keys(inputErrors).map((er) => {
      const erEl = inputErrors[er];
      nextInputErrors.push(erEl.msg);
      return inputErrors;
    });

    const nextErrHtml = (
      <ul>
        {nextInputErrors.map((er, i) => (
          <li key={i}>{er}</li>
        ))}
      </ul>
    );

    dispatch(
      setToast({
        status: 'danger',
        title: nextErrHtml,
      }),
    );
  }, [dispatch, inputErrors]);

  return (
    <Col md={12} lg={8}>
      {errors && <div>{errors.status === 404 && <div>Not Found</div>}</div>}
      {errors && errors.status === 400 && (
        <Alert variant="danger">
          {errors.errors.map((err) => (
            <div key={uuid()}>{err.msg}</div>
          ))}
        </Alert>
      )}

      <div>
        {isLoading ? (
          'Loading...'
        ) : (
          <EditForm
            values={values}
            handleChange={handleInputChange}
            isSubmitting={isSubmitting}
            isDeleting={isDeleting}
            errors={inputErrors}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            isNew={isNew}
            isCurrentUser={currentUserId === id}
            role={role}
          />
        )}
      </div>
    </Col>
  );
};

const EditForm = ({
  values,
  handleChange,
  isSubmitting,
  isDeleting,
  errors,
  handleCreate,
  handleUpdate,
  handleDelete,
  isNew,
  isCurrentUser,
  role,
}) => (
  <Card>
    <Card.Header className="fs-3 fw-bold">
      {isNew ? 'Add new User' : 'Edit User'}
    </Card.Header>

    <Card.Body>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormInput
          label="Name"
          type="text"
          name="name"
          value={values.name}
          changeHandler={handleChange}
          hasError={errors?.name?.msg}
        />

        <FormInput
          label="Email"
          type="email"
          name="email"
          value={values.email}
          changeHandler={handleChange}
          hasError={errors?.email?.msg}
        />

        {role === 'ADMIN' && (
          <FormSelect
            label="Role"
            name="role"
            value={values.role}
            options={[
              { value: 'ADMIN', label: 'Admin' },
              { value: 'USER', label: 'User' },
            ]}
            changeHandler={handleChange}
            hasError={errors?.role?.msg}
          />
        )}

        <FormInput
          label="Password"
          type="password"
          name="password"
          value={values.password}
          changeHandler={handleChange}
          hasError={errors?.password?.msg}
        />

        <FormInput
          label="Repeat Password"
          type="password"
          name="repassword"
          value={values.repassword}
          changeHandler={handleChange}
          hasError={errors?.repassword?.msg}
        />
      </Form>
    </Card.Body>

    <Card.Footer className="position-sticky bottom-0 bg-light">
      <Row sm="auto">
        {isNew ? (
          <div className="px-2">
            <Button
              type="submit"
              variant="primary"
              onClick={handleCreate}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                'Create'
              )}
            </Button>
          </div>
        ) : (
          <>
            <div className="px-2">
              <Button
                type="submit"
                variant="primary"
                onClick={handleUpdate}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Saving...
                  </>
                ) : (
                  'Update'
                )}
              </Button>
            </div>

            {!isCurrentUser && (
              <div className="px-1">
                <Button
                  type="button"
                  variant="danger"
                  onClick={handleDelete}
                  disabled={isDeleting}
                >
                  {isDeleting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Delete'
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </Row>
    </Card.Footer>
  </Card>
);
