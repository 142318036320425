import React, { useMemo } from 'react';
import { Card, Button, Form, CloseButton } from 'react-bootstrap';
import { htmlDecode } from '../../helpers/helpers';
import JoditEditor from '../JoditEditor';

const FormEditorList = ({ label, inputName, items, changeHandler }) => {
  const handleInputChange = (id, e) => {
    const { name, value } = e.target;

    const nextItems = [...items];
    nextItems[id][name] = value;

    changeHandler(inputName, nextItems);
  };

  const handleStateChange = (id, name, value) => {
    const nextItems = [...items];
    nextItems[id][name] = value;

    changeHandler(inputName, nextItems);
  };

  const handleAddItem = () => {
    const newItem = { title: '', content: '' };
    const nextState = [...items, newItem];

    changeHandler(inputName, nextState);
  };

  const handleRemoveItem = (id) => {
    const nextItems = [...items];
    nextItems.splice(id, 1);

    changeHandler(inputName, nextItems);
  };

  return (
    <div className="mb-4">
      <Form.Label>{label}</Form.Label>

      <div>
        {items?.map((item, i) => (
          <Item
            key={`item-${i}`}
            item={{ ...item, index: i }}
            index={i}
            handleRemoveItem={handleRemoveItem}
            handleInputChange={handleInputChange}
            handleStateChange={handleStateChange}
          />
        ))}
      </div>

      <div className="mt-3">
        <Button
          type="button"
          variant="outline-primary"
          className="w-100"
          onClick={handleAddItem}
          size="sm"
        >
          Add Item
        </Button>
      </div>
    </div>
  );
};

const Item = ({
  item,
  index,
  handleRemoveItem,
  handleInputChange,
  handleStateChange,
}) => (
  <Card className="mb-3">
    <Card.Header className="d-flex align-items-center">
      <CloseButton
        className="ms-auto"
        onClick={() => handleRemoveItem(index)}
      />
    </Card.Header>

    <Card.Body>
      <Form.Group className="mb-4">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          onChange={(e) => handleInputChange(index, e)}
          value={htmlDecode(item.title)}
          name="title"
        />
      </Form.Group>

      <Form.Group>
        <Editor
          label="Content"
          name="content"
          hash={item.hash || 0}
          defaultValue={item.content}
          changeHandler={(name, value) => handleStateChange(index, name, value)}
        />
      </Form.Group>
    </Card.Body>
  </Card>
);

export default FormEditorList;

const Editor = ({ hash, label, name, changeHandler, defaultValue }) =>
  useMemo(
    () => (
      <Form.Group className="mb-4">
        <Form.Label>{label}</Form.Label>
        <JoditEditor
          name={name}
          defaultValue={htmlDecode(defaultValue)}
          changeHandler={changeHandler}
        />
      </Form.Group>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hash],
  );
