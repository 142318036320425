import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const ListTopBar = ({ title, newItemLink, total }) => (
  <div className="d-block mb-4 mb-md-0">
    <div className="d-flex align-items-center">
      <h4>{title}</h4>

      {newItemLink && (
        <div className="ms-auto">
          <Button as={NavLink} to={newItemLink} variant="primary">
            <span>Add new</span>
          </Button>
        </div>
      )}
    </div>
    <div>total: {total || 0}</div>
  </div>
);

ListTopBar.propTypes = {
  title: PropTypes.string,
  newItemLink: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ListTopBar.defaultProps = {
  title: '',
  newItemLink: '',
  total: '',
};

export default ListTopBar;
