import {
  INTAKE_FETCH_ALL,
  INTAKE_FETCH_ALL_FLAT,
  INTAKE_FETCH_ONE,
  INTAKE_CREATE,
  INTAKE_UPDATE,
  INTAKE_DELETE,
  INTAKE_SET_ERRORS,
  INTAKE_CLEAR_STATE,
} from '../actions/actionTypes';

const initialState = {
  intakeList: {},
  intakeListFlat: [],
  intake: {},
  errors: null,
};

const intakeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTAKE_FETCH_ALL:
      return {
        ...state,
        intakeList: { ...action.payload },
        errors: null,
      };

    case INTAKE_FETCH_ALL_FLAT:
      return {
        ...state,
        intakeListFlat: [...action.payload],
        errors: null,
      };

    case INTAKE_FETCH_ONE:
      return {
        ...state,
        intake: { ...action.payload },
        errors: null,
      };

    case INTAKE_CREATE:
      return {
        ...state,
        intake: { ...action.payload },
        errors: null,
      };

    case INTAKE_UPDATE:
      return {
        ...state,
        intake: { ...action.payload },
        errors: null,
      };

    case INTAKE_DELETE:
      return {
        ...state,
        intake: { ...action.payload },
        errors: null,
      };

    case INTAKE_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case INTAKE_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default intakeReducer;
