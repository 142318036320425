import { useState } from 'react';

const useForm = (initialFieldValues) => {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((initState) => ({
      ...initState,
      [name]: value,
    }));
  };

  const handleStateChange = (key, value) => {
    setValues((initState) => ({
      ...initState,
      [key]: value,
    }));
  };

  const resetForm = () => {
    setValues(initialFieldValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleStateChange,
    resetForm,
  };
};

export default useForm;
