import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListGroup,
  Image,
  Col,
  Form,
  Card,
  Row,
  Button,
} from 'react-bootstrap';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { universityFetchAll } from '../actions/universityActions';
import Layout from '../components/Layout';
import ListTopBar from '../components/ListTopBar';
import PaginationBlock from '../components/PaginationBlock';
import useQuery from '../hooks/useQuery';
import { Routes } from '../json/routes';
import { universityLevels } from '../json/attributes';
import useForm from '../hooks/useForm';
import { intakeFetchAllFlat } from '../actions/intakeActions';
import { capitalize, htmlDecode } from '../helpers/helpers';

const UniversitiesPage = () => {
  const dispatch = useDispatch();
  const { universityList } = useSelector((state) => state.universityReducer);
  const { intakeListFlat } = useSelector((state) => state.intakeReducer);

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const title = query.get('title');
  const intake = query.get('intake');
  const level = query.get('level');
  const sort = query.get('sort');
  const page = query.get('page');

  const [isLoading, setIsLoading] = useState(true);
  const { values, handleStateChange, handleInputChange } = useForm({
    title: title || '',
    intake: intake || '',
    level: level || '',
    sort: sort || 'createdAt',
    page: page || 1,
  });

  const prepareQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    const params = {
      title: nextData.title,
      intake: nextData.intake,
      level: nextData.level,
      sort: nextData.sort,
      page: nextData.page,
    };

    return params;
  };

  const prepareSearchQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    nextData.title ? query.set('title', nextData.title) : query.delete('title');
    nextData.intake
      ? query.set('intake', nextData.intake)
      : query.delete('intake');
    nextData.level ? query.set('level', nextData.level) : query.delete('level');
    nextData.sort ? query.set('sort', nextData.sort) : query.delete('sort');
    nextData.page ? query.set('page', nextData.page) : query.delete('page');

    const searchParams = query.toString();

    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    });
  };

  const handlePage = (p) => {
    setIsLoading(true);

    handleStateChange('page', p);

    const params = prepareQuery(values, { page: p });

    dispatch(universityFetchAll(params));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setIsLoading(true);

    prepareSearchQuery(values, { page: 1 });

    const params = prepareQuery(values, { page: 1 });

    dispatch(universityFetchAll(params));
  };

  useEffect(() => {
    dispatch(intakeFetchAllFlat());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      ...(title && { title }),
      ...(intake && { intake }),
      ...(level && { level }),
      ...(sort && { sort }),
      ...(page && { page }),
    };

    dispatch(universityFetchAll(params));
  }, [dispatch, title, intake, level, sort, page]);

  useEffect(() => {
    if (universityList?.items) setIsLoading(false);
  }, [universityList]);

  return (
    <Layout>
      <ListTopBar
        title="Universities"
        newItemLink={Routes.University.new}
        total={universityList.total}
      />
      <ListSearchbar
        values={values}
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        intakeListFlat={intakeListFlat}
      />

      {isLoading ? (
        'Loading...'
      ) : (
        <ListBlock universityList={universityList} handlePage={handlePage} />
      )}
    </Layout>
  );
};

export default UniversitiesPage;

const ListSearchbar = ({
  handleSearch,
  values,
  handleInputChange,
  intakeListFlat,
}) => (
  <Card body className="mt-3">
    <form onSubmit={handleSearch}>
      <Row>
        <Form.Group as={Col} md={3}>
          <Form.Label>Title</Form.Label>
          <Form.Control
            value={values.title}
            name="title"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Intake</Form.Label>
          <Form.Select
            onChange={handleInputChange}
            value={values.intake}
            name="intake"
          >
            <option value="">Choose one...</option>
            {intakeListFlat?.map((option) => (
              <option key={option.id} value={option.id}>
                {`${capitalize(option.month)} ${option.year || ''}`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Institution</Form.Label>
          <Form.Select
            onChange={handleInputChange}
            value={values.level}
            name="level"
          >
            <option value="">Choose one...</option>
            {universityLevels?.map((option) => (
              <option key={option} value={option}>
                {capitalize(option)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Order by</Form.Label>

          <Form.Select
            value={values.sort}
            name="sort"
            onChange={handleInputChange}
          >
            <option value="createdAt">Created</option>
            <option value="title">Title</option>
          </Form.Select>
        </Form.Group>

        <Col md="auto" className="mt-auto">
          <Button variant="primary" onClick={handleSearch} type="submit">
            Filter
          </Button>
        </Col>
      </Row>
    </form>
  </Card>
);

const ListBlock = ({ universityList, handlePage }) => (
  <>
    <ListGroup className="mt-3">
      {universityList.items?.map((item) => (
        <ListGroup.Item
          key={item.id}
          as={NavLink}
          to={`${Routes.University.item}${item.id}`}
          variant={item.isActive ? '' : 'secondary'}
          action
        >
          <div className="d-flex align-items-center">
            <Col xs={6} md={1} className="me-3">
              {item.logo && (
                <Image
                  src={`${process.env.REACT_APP_API_PUBLIC}${item.logo}`}
                  thumbnail
                />
              )}
            </Col>
            <div>
              <span className="fs-5 fw-bold text-primary">
                {htmlDecode(item.title)}
              </span>

              {!item.isActive && (
                <div className="fs-6 fw-light text-muted">Not Active</div>
              )}
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>

    <PaginationBlock
      pageHandler={handlePage}
      currentPage={universityList.page}
      total={universityList.total}
    />
  </>
);
