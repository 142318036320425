import AuthService from '../services/authService';
import { setAuthToken } from '../helpers/auth';
import { USER_LOGIN, USER_LOGOUT, GET_ERRORS } from './actionTypes';
import STORAGE from '../helpers/storage';

export const loginUser = (userData) => (dispatch) => {
  AuthService.loginUser(userData)
    .then((res) => {
      const { accessToken } = res.data;

      STORAGE.setItem('jwtToken', accessToken);

      setAuthToken(accessToken);

      const decoded = STORAGE.jwtDecode(accessToken);

      dispatch({
        type: USER_LOGIN,
        payload: decoded,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      }),
    );
};

export const logoutUser = () => (dispatch) => {
  AuthService.logoutUser()
    .then(() => {
      STORAGE.clear();

      dispatch({
        type: USER_LOGOUT,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      }),
    );
};
