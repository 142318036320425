export const htmlDecode = (str = '') => {
  if (typeof str !== 'string' && !(str instanceof String)) return str;

  return str.replace(
    /&amp;|&lt;|&gt;|&#39;|&#x27;|&quot;|&#x2F;|&#x5C;/g,
    (tag) =>
      ({
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&#39;': "'",
        '&#x27;': "'",
        '&quot;': '"',
        '&#x2F;': '/',
        '&#x5C;': '\\',
      }[tag] || tag),
  );
};

// export const htmlDecode = (input) => {
//   if (typeof input !== 'string' && !(input instanceof String)) return input;
//
//   const e = document.createElement('div');
//   e.innerHTML = input;
//   return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
// };

export const buildFormData = (formData, data, parentKey, inputKey) => {
  if (Array.isArray(data) && !data.length) formData.append(parentKey, '');

  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
        inputKey || key,
      );
    });
  } else if (data instanceof File) {
    formData.append(inputKey, data);
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, htmlDecode(value));
  }

  return formData;
};

export const escapeValues = (values) => {
  const nextValues = Object.keys(values).reduce((acc, key) => {
    acc[key] = htmlDecode(values[key]);
    return acc;
  }, {});

  return nextValues;
};

export const capitalize = (str) => {
  if (!str || str === null || !str.length) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
};
