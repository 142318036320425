import {
  COURSE_FETCH_ALL,
  COURSE_FETCH_ALL_FLAT,
  COURSE_FETCH_ONE,
  COURSE_FETCH_USER_STATS,
  COURSE_CREATE,
  COURSE_UPDATE,
  COURSE_DELETE,
  COURSE_SET_ERRORS,
  COURSE_CLEAR_STATE,
} from '../actions/actionTypes';

const initialState = {
  courseList: {},
  course: {},
  userStatsList: {},
  errors: null,
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case COURSE_FETCH_ALL:
      return {
        ...state,
        courseList: { ...action.payload },
        errors: null,
      };

    case COURSE_FETCH_ALL_FLAT:
      return {
        ...state,
        courseListFlat: [...action.payload],
        errors: null,
      };

    case COURSE_FETCH_ONE:
      return {
        ...state,
        course: { ...action.payload },
        errors: null,
      };

    case COURSE_FETCH_USER_STATS:
      return {
        ...state,
        userStatsList: { ...action.payload },
        errors: null,
      };

    case COURSE_CREATE:
      return {
        ...state,
        course: { ...action.payload },
        errors: null,
      };

    case COURSE_UPDATE:
      return {
        ...state,
        course: { ...action.payload },
        errors: null,
      };

    case COURSE_DELETE:
      return {
        ...state,
        course: { ...action.payload },
        errors: null,
      };

    case COURSE_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case COURSE_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default courseReducer;
