import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFoundPage from '../pages/NotFoundPage';

const PrivateRoute = ({ component: Component, auth, allowRole, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated,
  );

  const currentRole = useSelector((state) => state.authReducer.user.role);

  if (allowRole && currentRole && currentRole !== allowRole) {
    return <NotFoundPage />;
  }

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
