import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { logoutUser } from '../../actions/authActions';
import { Routes } from '../../json/routes';
import styles from './NavBar.module.scss';

const NavBar = () => {
  const dispatch = useDispatch();
  const { id, name } = useSelector((state) => state.authReducer.user);
  const handleLogout = () => dispatch(logoutUser());

  return (
    <Navbar>
      <Container fluid>
        <Nav className="ms-auto">
          <NavDropdown
            title={
              <span className={styles.user}>
                <Icon.PersonCircle />
                {name}
              </span>
            }
            align="end"
          >
            <NavDropdown.Item as={NavLink} to={`${Routes.User.item}${id}`}>
              My Account
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBar;
