export const Routes = {
  Auth: {
    login: '/login',
  },
  University: {
    list: '/universities',
    single: '/universities/university/:id',
    new: '/universities/new',
    item: '/universities/university/',
  },
  Course: {
    list: '/courses',
    single: '/courses/course/:id',
    new: '/courses/new',
    item: '/courses/course/',
    userStats: '/courses-user-stats',
  },
  Subject: {
    list: '/subjects',
    single: '/subjects/subject/:id',
    new: '/subjects/new',
    item: '/subjects/subject/',
  },
  User: {
    list: '/users',
    single: '/users/user/:id',
    new: '/users/new',
    item: '/users/user/',
  },
  Intake: {
    list: '/intakes',
    single: '/intakes/intake/:id',
    new: '/intakes/new',
    item: '/intakes/intake/',
  },
  Location: {
    list: '/locations',
    single: '/locations/location/:id',
    new: '/locations/new',
    item: '/locations/location/',
  },
};
