import api from '../helpers/apiConfig';

const url = 'universities';
const headers = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
};

const getAll = (query) => api.get(`${url}/`, { params: { ...query } });

const getAllFlat = () => api.get(`${url}/all`);

const getOne = (id) => api.get(`${url}/${id}`);

const create = (data) => api.post(`${url}/`, data, headers);

const update = (id, data) => api.put(`${url}/${id}`, data, headers);

const remove = (id) => api.delete(`${url}/${id}`);

const UniversityService = {
  getAll,
  getAllFlat,
  getOne,
  create,
  update,
  remove,
};

export default UniversityService;
