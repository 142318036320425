import UniversityService from '../services/universityService';

import {
  UNIVERSITY_FETCH_ALL,
  UNIVERSITY_FETCH_ALL_FLAT,
  UNIVERSITY_FETCH_ONE,
  UNIVERSITY_CREATE,
  UNIVERSITY_UPDATE,
  UNIVERSITY_DELETE,
  UNIVERSITY_SET_ERRORS,
  UNIVERSITY_CLEAR_STATE,
} from './actionTypes';

export const universityFetchAll = (query) => (dispatch) => {
  UniversityService.getAll(query)
    .then(({ data }) =>
      dispatch({
        type: UNIVERSITY_FETCH_ALL,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: UNIVERSITY_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const universityFetchAllFlat = () => (dispatch) => {
  UniversityService.getAllFlat()
    .then(({ data }) =>
      dispatch({
        type: UNIVERSITY_FETCH_ALL_FLAT,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: UNIVERSITY_SET_ERRORS,
        payload: response,
      }),
    );
};

export const universityFetchOne = (id) => (dispatch) => {
  UniversityService.getOne(id)
    .then(({ data }) =>
      dispatch({
        type: UNIVERSITY_FETCH_ONE,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: UNIVERSITY_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const universityCreate = (data, onSuccess) => (dispatch) => {
  UniversityService.create(data)
    .then((res) => {
      dispatch({
        type: UNIVERSITY_CREATE,
        payload: res.data,
      });

      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) =>
      dispatch({
        type: UNIVERSITY_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const universityUpdate = (id, data, onSuccess) => (dispatch) => {
  UniversityService.update(id, data)
    .then((res) => {
      dispatch({
        type: UNIVERSITY_UPDATE,
        payload: res.data,
      });

      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) =>
      dispatch({
        type: UNIVERSITY_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const universityDeleteOne = (id, onSuccess) => (dispatch) => {
  UniversityService.remove(id)
    .then(({ data }) => {
      dispatch({
        type: UNIVERSITY_DELETE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch((err) =>
      dispatch({
        type: UNIVERSITY_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const universityClearState = () => (dispatch) => {
  dispatch({
    type: UNIVERSITY_CLEAR_STATE,
  });
};
