import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Form, Spinner, Col, Row, Alert } from 'react-bootstrap';
import uuid from 'react-uuid';
import { NavLink, useHistory } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Layout from '../components/Layout';
import {
  universityFetchOne,
  universityCreate,
  universityUpdate,
  universityDeleteOne,
  universityClearState,
} from '../actions/universityActions';
import { setToast } from '../actions/uiActions';
import useForm from '../hooks/useForm';
import { buildFormData, capitalize, htmlDecode } from '../helpers/helpers';
import FormInput from '../components/FormInput';
import FormTextarea from '../components/FormTextarea';
import FormEditor from '../components/FormEditor';
import FormCheckboxSelect from '../components/FormCheckboxSelect';
import FormRadioSelect from '../components/FormRadioSelect';
import FormMediaComponent from '../components/FormMediaComponent';
import FormLogo from '../components/FormLogo';
import FormKeyValue from '../components/FormKeyValue';
import FormEditorList from '../components/FormEditorList';
import FormEmbeddedVideos from '../components/FormEmbeddedVideos';
import FormCustomSelect from '../components/FormCustomSelect';
import {
  universityLevels,
  attendances,
  pathwayTypes,
} from '../json/attributes';
import { Routes } from '../json/routes';
import { courseFetchAllFlat } from '../actions/courseActions';
import { locationFetchAllFlat } from '../actions/locationActions';
import { intakeFetchAllFlat } from '../actions/intakeActions';

const UniversityPage = ({ match }) => {
  const { id } = match.params;

  return (
    <Layout>
      <div className="py-3">
        <Button variant="link" as={NavLink} to={Routes.University.list}>
          <Icon.ChevronLeft className="me-1" />
          Back to Univeristy list
        </Button>
      </div>

      {id && (
        <div className="mb-3">
          <NavLink to={`${Routes.Course.list}?university=${id}`}>
            Courses
          </NavLink>
        </div>
      )}

      <FromContainer id={id} />
    </Layout>
  );
};

export default UniversityPage;

const initialFieldValues = {
  cover: '',
  logo: '',
  title: '',
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  abstract: '',
  abstractScholarship: '',
  location: [],
  intake: [],
  level: '',
  pathway: [],
  attendance: [],
  ranking: '',
  averageFees: [],
  importantDates: [],
  website: '',
  overview: '',
  benefits: '',
  entryRequirements: '',
  entryRequirementsInternational: [],
  scholarship: '',
  gallery: [],
  galleryFiles: [],
  attachments: [],
  attachmentFiles: [],
  embeddedVideos: [],
  popularCourses: [],
  isActive: true,
  recruitmentRegion: [],
};

const FromContainer = ({ id }) => {
  const isNew = !id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { university, errors } = useSelector(
    (state) => state.universityReducer,
  );
  const { courseListFlat } = useSelector((state) => state.courseReducer);
  const { locationListFlat } = useSelector((state) => state.locationReducer);
  const { intakeListFlat } = useSelector((state) => state.intakeReducer);
  const { values, setValues, handleInputChange, handleStateChange } =
    useForm(initialFieldValues);
  const [isLoading, setIsLoading] = useState(!isNew);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inputErrors, setInputErrors] = useState();

  const onUpdateSuccess = () => {
    setIsSubmitting(false);
    setValues((initState) => ({ ...initState, galleryFiles: [] }));
    setValues((initState) => ({ ...initState, attachmentFiles: [] }));

    dispatch(
      setToast({
        status: 'success',
        title: 'University is updated!',
      }),
    );
  };

  const onCreateSuccess = (newUniversity) => {
    setIsSubmitting(false);
    setValues((initState) => ({ ...initState, galleryFiles: [] }));
    setValues((initState) => ({ ...initState, attachmentFiles: [] }));

    dispatch(
      setToast({
        status: 'success',
        title: 'University is created!',
      }),
    );

    if (newUniversity.id)
      history.push(`${Routes.University.item}${newUniversity.id}`);
  };

  const onDeleteSuccess = () => {
    setIsDeleting(false);

    dispatch(
      setToast({
        status: 'success',
        title: 'University is deleted!',
      }),
    );

    history.push(Routes.University.list);
  };

  const formatData = (newValues) => {
    const formData = new FormData();
    buildFormData(formData, newValues);
    return formData;
  };

  const handleCreate = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const data = formatData(values);

    dispatch(universityCreate(data, onCreateSuccess));
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (values.level === 'Pathway' && !values.pathway.length) {
      const pathwayError = {
        pathway: {
          value: '',
          msg: 'Missing Pathway!',
          param: 'pathway',
          location: 'body',
        },
      };

      const nextInputErrors = { ...inputErrors, ...pathwayError };

      setInputErrors(nextInputErrors);
      return;
    }

    setIsSubmitting(true);
    setInputErrors(null);

    const data = formatData(values);

    dispatch(universityUpdate(id, data, onUpdateSuccess));
  };

  const handleDelete = () => {
    setIsDeleting(true);

    if (window.confirm('Are you sure you want to delete this University?'))
      dispatch(universityDeleteOne(id, onDeleteSuccess));
  };

  useEffect(() => {
    setValues((initState) => ({ ...initState, ...university }));
  }, [university, setValues]);

  useEffect(() => {
    dispatch(locationFetchAllFlat());
    dispatch(intakeFetchAllFlat());

    if (id) {
      dispatch(universityFetchOne(id));
      dispatch(courseFetchAllFlat({ university: id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && university.id && id === university.id) setIsLoading(false);
  }, [id, university]);

  useEffect(() => {
    if (!errors) return;

    const nextInputErrors = errors.errors?.reduce((acc, err) => {
      acc[err.param] = err;
      return acc;
    }, {});

    setInputErrors(nextInputErrors);
    setIsLoading(false);
    setIsSubmitting(false);
    setIsDeleting(false);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(universityClearState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!inputErrors) return;

    const nextInputErrors = [];

    Object.keys(inputErrors).map((er) => {
      const erEl = inputErrors[er];
      nextInputErrors.push(erEl.msg);
      return inputErrors;
    });

    const nextErrHtml = (
      <ul>
        {nextInputErrors.map((er, i) => (
          <li key={i}>{er}</li>
        ))}
      </ul>
    );

    dispatch(
      setToast({
        status: 'danger',
        title: nextErrHtml,
      }),
    );
  }, [dispatch, inputErrors]);

  return (
    <Col md={12} lg={8}>
      {errors && <div>{errors.status === 404 && <div>Not Found</div>}</div>}
      {errors && errors.status === 400 && (
        <Alert variant="danger">
          {errors.errors?.map((err) => (
            <div key={uuid()}>{err.msg}</div>
          ))}
        </Alert>
      )}

      <div>
        {isLoading ? (
          'Loading...'
        ) : (
          <EditForm
            university={university}
            values={values}
            handleInputChange={handleInputChange}
            handleStateChange={handleStateChange}
            isSubmitting={isSubmitting}
            isDeleting={isDeleting}
            errors={inputErrors}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            isNew={isNew}
            locations={locationListFlat}
            intakes={intakeListFlat}
            courseListFlat={courseListFlat}
          />
        )}
      </div>
    </Col>
  );
};

const EditForm = ({
  university,
  values,
  handleInputChange,
  handleStateChange,
  isSubmitting,
  isDeleting,
  errors,
  handleCreate,
  handleUpdate,
  handleDelete,
  isNew,
  courseListFlat,
  locations,
  intakes,
}) => (
  <Card>
    <Card.Header className="fs-3 fw-bold">
      {isNew ? 'Add new University' : 'Edit University'}
    </Card.Header>

    <Card.Body>
      <Form onSubmit={(e) => e.preventDefault()} encType="multipart/form-data">
        <FormLogo
          label="Cover Image"
          name="cover"
          defaultValue={university.cover}
          changeHandler={handleStateChange}
        />
        {errors?.cover && (
          <div className="mt-1 fs-6 text-danger">{errors?.cover.msg}</div>
        )}
        <FormLogo
          label="Logo Image"
          name="logo"
          defaultValue={university.logo}
          changeHandler={handleStateChange}
        />
        {errors?.logo && (
          <div className="mt-1 fs-6 text-danger">{errors?.logo.msg}</div>
        )}
        <FormInput
          label="University Title*"
          type="text"
          name="title"
          value={values.title}
          changeHandler={handleInputChange}
          hasError={errors?.title?.msg}
        />

        <hr />
        <FormInput
          label="Meta Title"
          type="text"
          name="metaTitle"
          value={values.metaTitle}
          changeHandler={handleInputChange}
          hasError={errors?.metaTitle?.msg}
        />

        <FormTextarea
          label="Meta Description"
          name="metaDescription"
          value={values.metaDescription}
          rows={3}
          changeHandler={handleInputChange}
          hasError={errors?.metaDescription?.msg}
        />

        <FormInput
          label="Meta Keywords"
          type="text"
          name="metaKeywords"
          value={values.metaKeywords}
          changeHandler={handleInputChange}
          hasError={errors?.metaKeywords?.msg}
        />
        <hr />

        <div className="mb-4">
          <Form.Check
            type="switch"
            id="isActive"
            label="Is Active"
            name="isActive"
            checked={Boolean(values.isActive)}
            onChange={(e) => handleStateChange('isActive', e.target.checked)}
          />
        </div>
        <FormCheckboxSelect
          label="Recruitment Region Restriction (Global if none selected)"
          name="recruitmentRegion"
          value={values.recruitmentRegion}
          options={['UK', 'Bangladesh', 'India', 'Nigeria', 'Ghana']}
          changeHandler={handleStateChange}
          hasError={errors?.recruitmentRegion?.msg}
        />
        <FormTextarea
          label="Abstract (Preview Text)"
          name="abstract"
          value={values.abstract}
          rows={3}
          changeHandler={handleInputChange}
          hasError={errors?.abstract?.msg}
        />
        <FormInput
          label="Avg. Scholarship (Preview)"
          type="text"
          name="abstractScholarship"
          value={values.abstractScholarship}
          changeHandler={handleInputChange}
          hasError={errors?.abstractScholarship?.msg}
        />
        <FormInput
          label="Website"
          type="url"
          name="website"
          value={values.website}
          changeHandler={handleInputChange}
          hasError={errors?.website?.msg}
        />
        <FormCustomSelect
          label="Location"
          defaultValue={university.location || []}
          name="location"
          changeHandler={handleStateChange}
          options={locations?.reduce((acc, i) => {
            acc.push({
              value: i.id,
              label: htmlDecode(i.title),
            });
            return acc;
          }, [])}
          hasError={errors?.location?.msg}
        />
        <FormCustomSelect
          label="Intake"
          defaultValue={university.intake}
          name="intake"
          changeHandler={handleStateChange}
          options={intakes?.reduce((acc, i) => {
            acc.push({
              value: i.id,
              label: `${capitalize(i.month)} ${i.year || ''}`,
            });
            return acc;
          }, [])}
          hasError={errors?.intake?.msg}
        />

        <FormRadioSelect
          label="Institution"
          name="level"
          value={values.level}
          options={universityLevels}
          changeHandler={(key, val) => {
            if (val !== 'Pathway') handleStateChange('pathway', []);
            handleStateChange(key, val);
          }}
          hasError={errors?.level?.msg}
        />

        {values.level.includes('Pathway') && (
          <FormCheckboxSelect
            label="Pathway Type"
            name="pathway"
            value={values.pathway}
            options={pathwayTypes}
            changeHandler={handleStateChange}
            hasError={errors?.pathway?.msg}
          />
        )}

        <FormCheckboxSelect
          label="Attendance"
          name="attendance"
          value={values.attendance}
          options={attendances}
          changeHandler={handleStateChange}
          hasError={errors?.attendance?.msg}
        />
        <FormTextarea
          label="Ranking"
          name="ranking"
          value={values.ranking}
          rows={5}
          changeHandler={handleInputChange}
          hasError={errors?.ranking?.msg}
        />
        <FormKeyValue
          label="Average Fees"
          inputName="averageFees"
          items={values.averageFees}
          keyLabel="Fee type"
          valueLabel="Fee amount"
          addLabel="Add Average Fee"
          changeHandler={handleStateChange}
          hasError={errors?.averageFees?.msg}
        />
        <FormKeyValue
          label="Important Dates"
          inputName="importantDates"
          items={values.importantDates}
          keyLabel="Date description"
          valueLabel="Date"
          addLabel="Add Date"
          changeHandler={handleStateChange}
          hasError={errors?.importantDates?.msg}
        />
        <FormEditor
          label="Overview"
          name="overview"
          defaultValue={university.overview}
          changeHandler={handleStateChange}
          hasError={errors?.overview?.msg}
        />
        <FormEditor
          label="Why study at this university"
          name="benefits"
          defaultValue={university.benefits}
          changeHandler={handleStateChange}
          hasError={errors?.benefits?.msg}
        />
        <FormEditor
          label="Entry Requirements"
          name="entryRequirements"
          defaultValue={university.entryRequirements}
          changeHandler={handleStateChange}
          hasError={errors?.entryRequirements?.msg}
        />
        <FormEditorList
          label="International Entry Requirements"
          inputName="entryRequirementsInternational"
          defaultValue={university.entryRequirementsInternational}
          items={values.entryRequirementsInternational}
          changeHandler={handleStateChange}
          hasError={errors?.entryRequirementsInternational?.msg}
        />

        {!isNew && courseListFlat && (
          <FormCustomSelect
            label="Popular Courses"
            defaultValue={university.popularCourses || []}
            name="popularCourses"
            changeHandler={handleStateChange}
            options={courseListFlat?.reduce((acc, i) => {
              acc.push({
                value: i.id,
                label: htmlDecode(i.title),
              });
              return acc;
            }, [])}
            hasError={errors?.popularCourses?.msg}
          />
        )}

        <FormEditor
          label="Scholarship"
          name="scholarship"
          defaultValue={university.scholarship}
          changeHandler={handleStateChange}
          hasError={errors?.scholarship?.msg}
        />
        <FormMediaComponent
          label="Gallery"
          valuesName="gallery"
          filesName="galleryFiles"
          values={values.gallery}
          files={values.galleryFiles}
          changeHandler={handleStateChange}
          hasError={errors?.galleryFiles?.msg}
          accept="image/*"
        />
        <FormMediaComponent
          label="Attachments"
          valuesName="attachments"
          filesName="attachmentFiles"
          values={values.attachments}
          files={values.attachmentFiles}
          changeHandler={handleStateChange}
          hasError={errors?.attachmentFiles?.msg}
        />
        <FormEmbeddedVideos
          label="Embedded Videos"
          inputName="embeddedVideos"
          items={values.embeddedVideos}
          changeHandler={handleStateChange}
          hasError={errors?.embeddedVideos?.msg}
        />
      </Form>
    </Card.Body>

    <Card.Footer
      key={uuid()}
      className="position-sticky bottom-0 bg-light"
      style={{ zIndex: 99 }}
    >
      <Row sm="auto">
        {isNew ? (
          <div className="px-2">
            <Button
              type="submit"
              variant="primary"
              onClick={handleCreate}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                'Create'
              )}
            </Button>
          </div>
        ) : (
          <>
            <div className="px-2">
              <Button
                type="submit"
                variant="primary"
                onClick={handleUpdate}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Saving...
                  </>
                ) : (
                  'Update'
                )}
              </Button>
            </div>
            <div className="px-2">
              <Button
                type="button"
                variant="danger"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Delete'
                )}
              </Button>
            </div>
            <div className="ms-auto">
              <a href={Routes.University.new}>
                <Button as="div" variant="outline-primary">
                  Add new University
                </Button>
              </a>
            </div>
          </>
        )}
      </Row>
    </Card.Footer>
  </Card>
);
