import {
  USER_FETCH_ALL,
  USER_FETCH_ALL_FLAT,
  USER_FETCH_ONE,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  USER_SET_ERRORS,
  USER_CLEAR_STATE,
} from '../actions/actionTypes';

const initialState = {
  userList: {},
  userListFlat: [],
  user: {},
  errors: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_ALL:
      return {
        ...state,
        userList: { ...action.payload },
        errors: null,
      };

    case USER_FETCH_ALL_FLAT:
      return {
        ...state,
        userListFlat: [...action.payload],
        errors: null,
      };

    case USER_FETCH_ONE:
      return {
        ...state,
        user: { ...action.payload },
        errors: null,
      };

    case USER_CREATE:
      return {
        ...state,
        user: { ...action.payload },
        errors: null,
      };

    case USER_UPDATE:
      return {
        ...state,
        user: { ...action.payload },
        errors: null,
      };

    case USER_DELETE:
      return {
        ...state,
        user: { ...action.payload },
        errors: null,
      };

    case USER_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case USER_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
