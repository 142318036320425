import api from '../helpers/apiConfig';

const url = 'auth';

const loginUser = (userData) => api.post(`${url}/login`, userData);

const logoutUser = () => api.post(`${url}/logout`);

const AuthService = {
  loginUser,
  logoutUser,
};

export default AuthService;
