import api from '../helpers/apiConfig';

const url = 'users';

const getAll = (query) => api.get(`${url}/`, { params: { ...query } });

const getAllFlat = () => api.get(`${url}/all`);

const getOne = (id) => api.get(`${url}/${id}`);

const create = (data) => api.post(`${url}/`, data);

const update = (id, data) => api.put(`${url}/${id}`, data);

const remove = (id) => api.delete(`${url}/${id}`);

const UserService = {
  getAll,
  getAllFlat,
  getOne,
  create,
  update,
  remove,
};

export default UserService;
