import LocationService from '../services/locationService';

import {
  LOCATION_FETCH_ALL,
  LOCATION_FETCH_ALL_FLAT,
  LOCATION_FETCH_ONE,
  LOCATION_CREATE,
  LOCATION_UPDATE,
  LOCATION_DELETE,
  LOCATION_SET_ERRORS,
  LOCATION_CLEAR_STATE,
} from './actionTypes';

export const locationFetchAll = (query) => (dispatch) => {
  LocationService.getAll(query)
    .then(({ data }) =>
      dispatch({
        type: LOCATION_FETCH_ALL,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: LOCATION_SET_ERRORS,
        payload: response,
      }),
    );
};

export const locationFetchAllFlat = () => (dispatch) => {
  LocationService.getAllFlat()
    .then(({ data }) =>
      dispatch({
        type: LOCATION_FETCH_ALL_FLAT,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: LOCATION_SET_ERRORS,
        payload: response,
      }),
    );
};

export const locationFetchOne = (id) => (dispatch) => {
  LocationService.getOne(id)
    .then(({ data }) =>
      dispatch({
        type: LOCATION_FETCH_ONE,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: LOCATION_SET_ERRORS,
        payload: response,
      }),
    );
};

export const locationCreate = (newData, onSuccess) => (dispatch) => {
  LocationService.create(newData)
    .then(({ data }) => {
      dispatch({
        type: LOCATION_CREATE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: LOCATION_SET_ERRORS,
        payload: response,
      }),
    );
};

export const locationUpdate = (id, newData, onSuccess) => (dispatch) => {
  LocationService.update(id, newData)
    .then(({ data }) => {
      dispatch({
        type: LOCATION_UPDATE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: LOCATION_SET_ERRORS,
        payload: response,
      }),
    );
};

export const locationDeleteOne = (id, onSuccess) => (dispatch) => {
  LocationService.remove(id)
    .then(({ data }) => {
      dispatch({
        type: LOCATION_DELETE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: LOCATION_SET_ERRORS,
        payload: response,
      }),
    );
};

export const locationClearState = () => (dispatch) => {
  dispatch({
    type: LOCATION_CLEAR_STATE,
  });
};
