import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Form, Spinner, Alert, Col, Row } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import * as Icon from 'react-bootstrap-icons';
import Layout from '../components/Layout';
import {
  subjectFetchOne,
  subjectCreate,
  subjectUpdate,
  subjectDeleteOne,
  subjectClearState,
} from '../actions/subjectActions';
import { setToast } from '../actions/uiActions';
import useForm from '../hooks/useForm';
import FormInput from '../components/FormInput';
import { Routes } from '../json/routes';
import { escapeValues } from '../helpers/helpers';

const SubjectPage = ({ match }) => {
  const { id } = match.params;

  return (
    <Layout>
      <div className="py-3">
        <Button variant="link" as={NavLink} to={Routes.Subject.list}>
          <Icon.ChevronLeft className="me-1" />
          Back to Subject list
        </Button>
      </div>

      <FromContainer id={id} />
    </Layout>
  );
};

export default SubjectPage;

const initialFieldValues = {
  title: '',
};

const FromContainer = ({ id }) => {
  const isNew = !id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { subject, errors } = useSelector((state) => state.subjectReducer);
  const { values, setValues, handleInputChange } = useForm(initialFieldValues);
  const [isLoading, setIsLoading] = useState(!isNew);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inputErrors, setInputErrors] = useState();

  const onUpdateSuccess = () => {
    setIsSubmitting(false);

    dispatch(
      setToast({
        status: 'success',
        title: 'Subject is updated!',
      }),
    );
  };

  const onCreateSuccess = (newSubject) => {
    setIsSubmitting(false);

    dispatch(
      setToast({
        status: 'success',
        title: 'Subject is created!',
      }),
    );

    if (newSubject.id) history.push(`${Routes.Subject.item}${newSubject.id}`);
  };

  const onDeleteSuccess = () => {
    setIsDeleting(false);

    dispatch(
      setToast({
        status: 'success',
        title: 'Subject is deleted!',
      }),
    );

    history.push(Routes.Subject.list);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const data = escapeValues(values);

    dispatch(subjectCreate(data, onCreateSuccess));
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const data = escapeValues(values);

    dispatch(subjectUpdate(id, data, onUpdateSuccess));
  };

  const handleDelete = () => {
    setIsDeleting(true);

    if (window.confirm('Are you sure you want to delete this Subject?'))
      dispatch(subjectDeleteOne(id, onDeleteSuccess));
  };

  useEffect(() => {
    setValues((initState) => ({ ...initState, ...subject }));
  }, [subject, setValues]);

  useEffect(() => {
    if (id) dispatch(subjectFetchOne(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id && subject.id && id === subject.id) setIsLoading(false);
  }, [id, subject]);

  useEffect(() => {
    if (!errors) return;

    const nextInputErrors = errors.errors?.reduce((acc, err) => {
      acc[err.param] = err;
      return acc;
    }, {});

    setInputErrors(nextInputErrors);
    setIsLoading(false);
    setIsSubmitting(false);
    setIsDeleting(false);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(subjectClearState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!inputErrors) return;

    const nextInputErrors = [];

    Object.keys(inputErrors).map((er) => {
      const erEl = inputErrors[er];
      nextInputErrors.push(erEl.msg);
      return inputErrors;
    });

    const nextErrHtml = (
      <ul>
        {nextInputErrors.map((er, i) => (
          <li key={i}>{er}</li>
        ))}
      </ul>
    );

    dispatch(
      setToast({
        status: 'danger',
        title: nextErrHtml,
      }),
    );
  }, [dispatch, inputErrors]);

  return (
    <Col md={12} lg={8}>
      {errors && <div>{errors.status === 404 && <div>Not Found</div>}</div>}
      {errors && errors.status === 400 && (
        <Alert variant="danger">
          {errors.errors.map((err) => (
            <div key={uuid()}>{err.msg}</div>
          ))}
        </Alert>
      )}

      <div>
        {isLoading ? (
          'Loading...'
        ) : (
          <EditForm
            values={values}
            handleChange={handleInputChange}
            isSubmitting={isSubmitting}
            isDeleting={isDeleting}
            errors={inputErrors}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            isNew={isNew}
          />
        )}
      </div>
    </Col>
  );
};

const EditForm = ({
  values,
  handleChange,
  isSubmitting,
  isDeleting,
  errors,
  handleCreate,
  handleUpdate,
  handleDelete,
  isNew,
}) => (
  <Card>
    <Card.Header className="fs-3 fw-bold">
      {isNew ? 'Add new Subject' : 'Edit Subject'}
    </Card.Header>

    <Card.Body>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormInput
          label="Title*"
          type="text"
          name="title"
          value={values.title}
          changeHandler={handleChange}
          hasError={errors?.title?.msg}
        />
      </Form>
    </Card.Body>

    <Card.Footer key={uuid()} className="position-sticky bottom-0 bg-light">
      <Row sm="auto">
        {isNew ? (
          <div className="px-2">
            <Button
              type="submit"
              variant="primary"
              onClick={handleCreate}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                'Create'
              )}
            </Button>
          </div>
        ) : (
          <>
            <div className="px-2">
              <Button
                type="submit"
                variant="primary"
                onClick={handleUpdate}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Saving...
                  </>
                ) : (
                  'Update'
                )}
              </Button>
            </div>
            <div className="px-2">
              <Button
                type="button"
                variant="danger"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Delete'
                )}
              </Button>
            </div>
            <div className="ms-auto">
              <a href={Routes.Subject.new}>
                <Button as="div" variant="outline-primary">
                  Add new Subject
                </Button>
              </a>
            </div>
          </>
        )}
      </Row>
    </Card.Footer>
  </Card>
);
