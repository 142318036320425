import React from 'react';
import { Provider, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import store from './store/store';
import STORAGE from './helpers/storage';
import { setAuthToken, setCurrentUser } from './helpers/auth';

import { Routes } from './json/routes';

import PrivateRoute from './components/PrivateRoute';
import AuthRoute from './components/AuthRoute';

import LoginPage from './pages/LoginPage';
// import IndexPage from './pages/IndexPage';

import UniversitiesPage from './pages/UniversitiesPage';
import UniversityPage from './pages/UniversityPage';

import CoursePage from './pages/CoursePage';
import CoursesPage from './pages/CoursesPage';
import CoursesUserStatsPage from './pages/CoursesUserStatsPage';

import SubjectPage from './pages/SubjectPage';
import SubjectsPage from './pages/SubjectsPage';

import UserPage from './pages/UserPage';
import UsersPage from './pages/UsersPage';

import IntakePage from './pages/IntakePage';
import IntakesPage from './pages/IntakesPage';

import LocationPage from './pages/LocationPage';
import LocationsPage from './pages/LocationsPage';

import NotFoundPage from './pages/NotFoundPage';

import './App.scss';

const token = STORAGE.getItem('jwtToken');

if (token && token !== 'undefined') {
  setAuthToken(token);

  const decoded = STORAGE.jwtDecode(token);

  store.dispatch(setCurrentUser(decoded));
}

const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <AuthRoute exact path={Routes.Auth.login} component={LoginPage} />

        <Route exact path="/" render={() => <DeafultRoute />} />

        {/*
        <PrivateRoute exact path="/" component={IndexPage} />
        */}

        <PrivateRoute
          exact
          path={Routes.University.new}
          component={UniversityPage}
        />
        <PrivateRoute
          path={Routes.University.single}
          component={UniversityPage}
        />
        <PrivateRoute
          exact
          path={Routes.University.list}
          component={UniversitiesPage}
        />

        <PrivateRoute exact path={Routes.Course.new} component={CoursePage} />
        <PrivateRoute path={Routes.Course.single} component={CoursePage} />
        <PrivateRoute exact path={Routes.Course.list} component={CoursesPage} />
        <PrivateRoute
          exact
          path={Routes.Course.userStats}
          allowRole="ADMIN"
          component={CoursesUserStatsPage}
        />

        <PrivateRoute exact path={Routes.Subject.new} component={SubjectPage} />
        <PrivateRoute path={Routes.Subject.single} component={SubjectPage} />
        <PrivateRoute
          exact
          path={Routes.Subject.list}
          component={SubjectsPage}
        />

        <PrivateRoute
          exact
          path={Routes.User.new}
          allowRole="ADMIN"
          component={UserPage}
        />
        <PrivateRoute
          path={Routes.User.single}
          allowRole="ADMIN"
          component={UserPage}
        />
        <PrivateRoute
          exact
          path={Routes.User.list}
          allowRole="ADMIN"
          component={UsersPage}
        />

        <PrivateRoute exact path={Routes.Intake.new} component={IntakePage} />
        <PrivateRoute path={Routes.Intake.single} component={IntakePage} />
        <PrivateRoute exact path={Routes.Intake.list} component={IntakesPage} />

        <PrivateRoute
          exact
          path={Routes.Location.new}
          component={LocationPage}
        />
        <PrivateRoute path={Routes.Location.single} component={LocationPage} />
        <PrivateRoute
          exact
          path={Routes.Location.list}
          component={LocationsPage}
        />

        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    </Router>
  </Provider>
);

const DeafultRoute = () => {
  const currentRole = useSelector((state) => state.authReducer.user.role);

  const deafultRoute =
    currentRole === 'ADMIN' ? Routes.University.list : Routes.Course.list;

  return <Redirect to={deafultRoute} />;
};

export default App;
