import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, Toast } from 'react-bootstrap';
import cx from 'classnames';
import { setToast } from '../../actions/uiActions';
import styles from './Toast.module.scss';

const ToastElement = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.uiReducer.toast);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        dispatch(setToast(null));
      }, 6000);
    }
  }, [toast, dispatch]);

  return (
    <ToastContainer
      className={cx([styles.wrapper, 'position-fixed top-0 end-0 py-2 px-2'])}
    >
      {toast && (
        <Toast bg={toast.status || 'info'}>
          <Toast.Body className="text-white">{toast.title}</Toast.Body>
        </Toast>
      )}
    </ToastContainer>
  );
};

export default ToastElement;
