import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const btns = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'indent',
  'outdent',
  'paragraph',
  'copyformat',
  'table',
  'link',
  'undo',
  'redo',
  'fullsize',
  'preview',
  'source',
];

const JoditEditorComponent = ({ name, changeHandler, defaultValue }) => {
  const editorRef = useRef(null);

  const editorConfig = {
    readonly: false,
    disablePlugins: [
      'about',
      'file',
      'video',
      'media',
      'image',
      'image-processor',
      'image-properties',
      // 'source',
      'print',
      'search',
      'iframe',
      'font',
      'color',
      'class-span',
      'clipboard',
      'symbols',
      'hr',
    ],
    buttons: btns,
    buttonsMD: btns,
    buttonsSM: btns,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'insert_clear_html',
    uploader: { url: 'none' },
    cleanHTML: {
      cleanOnPaste: true,
      replaceNBSP: true,
    },
    cleanWhitespace: true,
  };

  return (
    <JoditEditor
      ref={editorRef}
      value={defaultValue}
      config={editorConfig}
      onChange={(newContent) => changeHandler(name, newContent)}
    />
  );
};

export default JoditEditorComponent;
