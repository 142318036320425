import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Alert, Card, Button, Form, Spinner } from 'react-bootstrap';
import uuid from 'react-uuid';
import { NavLink, useHistory } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Layout from '../components/Layout';
import {
  courseFetchOne,
  courseUpdate,
  courseCreate,
  courseDeleteOne,
  courseClearState,
} from '../actions/courseActions';
import { subjectFetchAllFlat } from '../actions/subjectActions';
import { intakeFetchAllFlat } from '../actions/intakeActions';
import { universityFetchAllFlat } from '../actions/universityActions';
import { setToast } from '../actions/uiActions';
import useForm from '../hooks/useForm';
import FormInput from '../components/FormInput';
import FormTextarea from '../components/FormTextarea';
import FormEditor from '../components/FormEditor';
import FormSelect from '../components/FormSelect';
import FormCheckboxSelect from '../components/FormCheckboxSelect';
import FormMediaComponent from '../components/FormMediaComponent';
import { buildFormData, capitalize } from '../helpers/helpers';
import useQuery from '../hooks/useQuery';
import { levels, attendances } from '../json/attributes';
import { Routes } from '../json/routes';
import FormKeyValue from '../components/FormKeyValue';
import CourseDetailsComponent from '../components/CourseDetailsComponent';
import FormCustomSelect from '../components/FormCustomSelect';

const CoursePage = ({ match }) => {
  const { id } = match.params;
  const query = useQuery();
  const universityId = query.get('university');

  return (
    <Layout>
      <div className="py-3">
        <Button variant="link" as={NavLink} to={Routes.Course.list}>
          <Icon.ChevronLeft className="me-1" />
          Back to Course list
        </Button>
      </div>

      <FromContainer id={id} universityId={universityId} />
    </Layout>
  );
};

export default CoursePage;

const initialFieldValues = {
  title: '',
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  university: '',
  subject: undefined,
  level: [],
  attendance: [],
  duration: [],
  intake: [],
  tuitionFee: [],
  website: '',
  overview: '',
  entryRequirements: '',
  careerProspects: '',
  courseDetailsLead: '',
  courseDetails: [],
  isActive: true,
  attachments: [],
  attachmentFiles: [],
  embeddedVideo: '',
  embeddedVideoCaption: '',
};

const FromContainer = ({ id, universityId }) => {
  const isNew = !id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { course, errors } = useSelector((state) => state.courseReducer);
  const { subjectListFlat } = useSelector((state) => state.subjectReducer);
  const { intakeListFlat } = useSelector((state) => state.intakeReducer);
  const { universityListFlat } = useSelector(
    (state) => state.universityReducer,
  );
  const { values, setValues, handleInputChange, handleStateChange } =
    useForm(initialFieldValues);
  const [isLoading, setIsLoading] = useState(!isNew);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inputErrors, setInputErrors] = useState();

  const onUpdateSuccess = () => {
    setIsSubmitting(false);
    setValues((initState) => ({ ...initState, attachmentFiles: [] }));

    dispatch(
      setToast({
        status: 'success',
        title: 'Course is updated!',
      }),
    );
  };

  const onCreateSuccess = (newCourse) => {
    setIsSubmitting(false);
    setValues((initState) => ({ ...initState, attachmentFiles: [] }));

    dispatch(
      setToast({
        status: 'success',
        title: 'Course is created!',
      }),
    );

    if (newCourse.id) history.push(`${Routes.Course.item}${newCourse.id}`);
  };

  const onDeleteSuccess = () => {
    setIsDeleting(false);

    dispatch(
      setToast({
        status: 'success',
        title: 'Course is deleted!',
      }),
    );

    history.push(Routes.Course.list);
  };

  const formatData = (newValues) => {
    const formData = new FormData();
    buildFormData(formData, newValues);
    return formData;
  };

  const handleCreate = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const data = formatData(values);

    dispatch(courseCreate(data, onCreateSuccess));
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const data = formatData(values);

    dispatch(courseUpdate(id, data, onUpdateSuccess));
  };

  const handleDelete = () => {
    setIsDeleting(true);

    if (window.confirm('Are you sure you want to delete this Course?'))
      dispatch(courseDeleteOne(id, onDeleteSuccess));
  };

  useEffect(() => {
    const nextCourse = { ...course };
    if (universityId) nextCourse.university = universityId;
    setValues((initState) => ({ ...initState, ...nextCourse }));
  }, [universityId, course, setValues]);

  useEffect(() => {
    dispatch(subjectFetchAllFlat());
    dispatch(intakeFetchAllFlat());
    dispatch(universityFetchAllFlat());

    if (id) dispatch(courseFetchOne(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id && course.id && id === course.id) setIsLoading(false);
  }, [id, course]);

  useEffect(() => {
    if (!errors) return;

    const nextInputErrors = errors.errors?.reduce((acc, err) => {
      acc[err.param] = err;
      return acc;
    }, {});

    setInputErrors(nextInputErrors);
    setIsLoading(false);
    setIsSubmitting(false);
    setIsDeleting(false);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(courseClearState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!inputErrors) return;

    const nextInputErrors = [];

    Object.keys(inputErrors).map((er) => {
      const erEl = inputErrors[er];
      nextInputErrors.push(erEl.msg);
      return inputErrors;
    });

    const nextErrHtml = (
      <ul>
        {nextInputErrors.map((er, i) => (
          <li key={i}>{er}</li>
        ))}
      </ul>
    );

    dispatch(
      setToast({
        status: 'danger',
        title: nextErrHtml,
      }),
    );
  }, [dispatch, inputErrors]);

  return (
    <Col md={12} lg={8}>
      {errors && <div>{errors.status === 404 && <div>Not Found</div>}</div>}
      {errors && errors.status === 400 && (
        <Alert variant="danger">
          {errors.errors.map((err) => (
            <div key={uuid()}>{err.msg}</div>
          ))}
        </Alert>
      )}

      <div>
        {isLoading ? (
          'Loading...'
        ) : (
          <EditForm
            course={course}
            values={values}
            handleInputChange={handleInputChange}
            handleStateChange={handleStateChange}
            isSubmitting={isSubmitting}
            isDeleting={isDeleting}
            errors={inputErrors}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            isNew={isNew}
            subjects={subjectListFlat}
            intakes={intakeListFlat}
            universities={universityListFlat}
          />
        )}
      </div>
    </Col>
  );
};

const EditForm = ({
  course,
  values,
  handleInputChange,
  handleStateChange,
  isSubmitting,
  isDeleting,
  errors,
  handleCreate,
  handleUpdate,
  handleDelete,
  isNew,
  subjects,
  intakes,
  universities,
}) => (
  <Card>
    <Card.Header className="fs-3 fw-bold">
      {isNew ? 'Add new Course' : 'Edit Course'}
    </Card.Header>

    <Card.Body>
      <Form onSubmit={(e) => e.preventDefault()} encType="multipart/form-data">
        <FormInput
          label="Course Title*"
          type="text"
          name="title"
          value={values.title}
          changeHandler={handleInputChange}
          hasError={errors?.title?.msg}
        />

        <hr />
        <FormInput
          label="Meta Title"
          type="text"
          name="metaTitle"
          value={values.metaTitle}
          changeHandler={handleInputChange}
          hasError={errors?.metaTitle?.msg}
        />

        <FormTextarea
          label="Meta Description"
          name="metaDescription"
          value={values.metaDescription}
          rows={3}
          changeHandler={handleInputChange}
          hasError={errors?.metaDescription?.msg}
        />

        <FormInput
          label="Meta Keywords"
          type="text"
          name="metaKeywords"
          value={values.metaKeywords}
          changeHandler={handleInputChange}
          hasError={errors?.metaKeywords?.msg}
        />
        <hr />

        <div className="mb-3">
          <Form.Check
            type="switch"
            id="isActive"
            label="Is Active"
            name="isActive"
            checked={Boolean(values.isActive)}
            onChange={(e) => handleStateChange('isActive', e.target.checked)}
          />
        </div>

        <FormSelect
          label="University*"
          name="university"
          value={values.university}
          options={universities.map((u) => ({ value: u.id, label: u.title }))}
          changeHandler={handleInputChange}
          hasError={errors?.university?.msg}
        />

        <FormInput
          label="Website"
          type="url"
          name="website"
          value={values.website}
          changeHandler={handleInputChange}
          hasError={errors?.website?.msg}
        />

        <FormSelect
          label="Subject"
          name="subject"
          value={values.subject}
          options={subjects.map((s) => ({ value: s.id, label: s.title }))}
          changeHandler={handleInputChange}
          hasError={errors?.subject?.msg}
        />

        <FormCheckboxSelect
          label="Level"
          name="level"
          value={values.level}
          options={levels}
          changeHandler={handleStateChange}
          hasError={errors?.level?.msg}
        />

        <FormCheckboxSelect
          label="Attendance"
          name="attendance"
          value={values.attendance}
          options={attendances}
          changeHandler={handleStateChange}
          hasError={errors?.attendance?.msg}
        />

        <FormKeyValue
          label="Duration"
          inputName="duration"
          keyLabel="Attendance type"
          valueLabel="Duration"
          addLabel="Add Duration"
          items={values.duration}
          changeHandler={handleStateChange}
          hasError={errors?.duration?.msg}
        />

        <FormCustomSelect
          label="Intake"
          defaultValue={course.intake}
          name="intake"
          changeHandler={handleStateChange}
          options={intakes?.reduce((acc, i) => {
            acc.push({
              value: i.id,
              label: `${capitalize(i.month)} ${i.year || ''}`,
            });
            return acc;
          }, [])}
          hasError={errors?.intake?.msg}
        />

        <FormKeyValue
          label="Tuition Fee"
          inputName="tuitionFee"
          keyLabel="Tuition Fee Type"
          valueLabel="Tuition Fee Value"
          addLabel="Add Tuition Fee"
          items={values.tuitionFee}
          changeHandler={handleStateChange}
          hasError={errors?.tuitionFee?.msg}
        />

        <FormEditor
          label="Overview"
          name="overview"
          defaultValue={course.overview}
          changeHandler={handleStateChange}
          hasError={errors?.overview?.msg}
        />

        <FormEditor
          label="Entry Requirements"
          name="entryRequirements"
          defaultValue={course.entryRequirements}
          changeHandler={handleStateChange}
          hasError={errors?.entryRequirements?.msg}
        />

        <FormEditor
          label="Career Prospects"
          name="careerProspects"
          defaultValue={course.careerProspects}
          changeHandler={handleStateChange}
          hasError={errors?.careerProspects?.msg}
        />

        <div>
          <h4>Course Details</h4>

          <FormEditor
            label="Course Details Lead text"
            name="courseDetailsLead"
            defaultValue={course.courseDetailsLead}
            changeHandler={handleStateChange}
          />
          {errors?.courseDetailsLead && (
            <div>{errors?.courseDetailsLead.msg}</div>
          )}

          <CourseDetailsComponent
            inputName="courseDetails"
            items={values.courseDetails}
            changeHandler={handleStateChange}
          />
          {errors?.courseDetails && <div>{errors?.courseDetails.msg}</div>}
        </div>

        <FormMediaComponent
          label="Attachments"
          valuesName="attachments"
          filesName="attachmentFiles"
          values={values.attachments}
          files={values.attachmentFiles}
          changeHandler={handleStateChange}
          hasError={errors?.attachmentFiles?.msg}
        />

        <div>
          <h6>Embedded Video</h6>

          <FormInput
            label="URL"
            type="url"
            name="embeddedVideo"
            value={values.embeddedVideo}
            changeHandler={handleInputChange}
            hasError={errors?.embeddedVideo?.msg}
          />

          <FormInput
            label="Caption"
            type="text"
            name="embeddedVideoCaption"
            value={values.embeddedVideoCaption}
            changeHandler={handleInputChange}
            hasError={errors?.embeddedVideoCaption?.msg}
          />
        </div>
      </Form>
    </Card.Body>

    <Card.Footer
      key={uuid()}
      className="position-sticky bottom-0 bg-light"
      style={{ zIndex: 99 }}
    >
      <Row sm="auto">
        {isNew ? (
          <div className="px-2">
            <Button
              type="submit"
              variant="primary"
              onClick={handleCreate}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Saving...
                </>
              ) : (
                'Create'
              )}
            </Button>
          </div>
        ) : (
          <>
            <div className="px-2">
              <Button
                type="submit"
                variant="primary"
                onClick={handleUpdate}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Saving...
                  </>
                ) : (
                  'Update'
                )}
              </Button>
            </div>
            <div className="px-2">
              <Button
                type="button"
                variant="danger"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Delete'
                )}
              </Button>
            </div>
            <div className="ms-auto">
              <a href={Routes.Course.new}>
                <Button as="div" variant="outline-primary">
                  Add new Course
                </Button>
              </a>
            </div>
          </>
        )}
      </Row>
    </Card.Footer>
  </Card>
);
