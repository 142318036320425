import React from 'react';
import { Card, Button, Form, CloseButton } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Icon from 'react-bootstrap-icons';
import { htmlDecode } from '../../helpers/helpers';

const FormKeyValue = ({ label, inputName, items, changeHandler }) => {
  const handleInputChange = (id, e) => {
    const { name, value, checked, type } = e.target;

    const nextItems = [...items];
    nextItems[id][name] = type === 'checkbox' ? checked : value;

    changeHandler(inputName, nextItems);
  };

  const handleAddItem = () => {
    const newItem = { url: '', caption: '' };
    const nextState = [...items, newItem];

    changeHandler(inputName, nextState);
  };

  const handleRemoveItem = (id) => {
    const nextItems = [...items];
    nextItems.splice(id, 1);

    changeHandler(inputName, nextItems);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const nextItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    changeHandler(inputName, nextItems);
  };

  return (
    <div className="mb-4">
      <Form.Label>{label}</Form.Label>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items?.map((item, i) => (
                <Item
                  key={`item-${i}`}
                  item={item}
                  index={i}
                  handleRemoveItem={handleRemoveItem}
                  handleInputChange={handleInputChange}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mt-3">
        <Button
          type="button"
          variant="outline-primary"
          className="w-100"
          onClick={handleAddItem}
          size="sm"
        >
          Add Item
        </Button>
      </div>
    </div>
  );
};

const Item = ({ item, index, handleRemoveItem, handleInputChange }) => (
  <Draggable draggableId={`item-${index}`} index={index}>
    {(provided) => (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="mb-3"
      >
        <Card.Header className="d-flex align-items-center">
          <Icon.List />

          <CloseButton
            className="ms-auto"
            onClick={() => handleRemoveItem(index)}
          />
        </Card.Header>

        <Card.Body>
          <Form.Group className="mb-2">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="url"
              onChange={(e) => handleInputChange(index, e)}
              value={htmlDecode(item.url)}
              name="url"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Caption</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => handleInputChange(index, e)}
              value={htmlDecode(item.caption)}
              name="caption"
            />
          </Form.Group>

          <Form.Check
            type="switch"
            id="isMain"
            label="Is Main"
            name="isMain"
            checked={Boolean(item.isMain)}
            onChange={(e) => handleInputChange(index, e)}
          />
        </Card.Body>
      </Card>
    )}
  </Draggable>
);

export default FormKeyValue;
