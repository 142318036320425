import api from '../helpers/apiConfig';

const url = 'courses';
const headers = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
};

const getAll = (query) => api.get(`${url}/`, { params: { ...query } });

const getAllFlat = (query) => api.get(`${url}/all`, { params: { ...query } });

const getOne = (id) => api.get(`${url}/${id}`);

const getUserStats = (query) =>
  api.get(`${url}/user-stats`, { params: { ...query } });

const create = (data) => api.post(`${url}/`, data, headers);

const update = (id, data) => api.put(`${url}/${id}`, data, headers);

const remove = (id) => api.delete(`${url}/${id}`);

const CourseService = {
  getAll,
  getAllFlat,
  getOne,
  getUserStats,
  create,
  update,
  remove,
};

export default CourseService;
