import React from 'react';
import { Card, Button, Form, CloseButton } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Icon from 'react-bootstrap-icons';
import FormInput from '../FormInput';
import FormTextarea from '../FormTextarea';

const CourseDetailsComponent = ({ label, inputName, items, changeHandler }) => {
  const handleAddItem = () => {
    const newItem = { title: '', modules: '' };
    const nextState = [...items, newItem];

    changeHandler(inputName, nextState);
  };

  const handleRemoveItem = (id) => {
    const nextItems = [...items];
    nextItems.splice(id, 1);

    changeHandler(inputName, nextItems);
  };

  const handleInputChange = (id, e) => {
    const { name, value } = e.target;

    const nextItems = [...items];
    nextItems[id][name] = value;

    changeHandler(inputName, nextItems);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const nextItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    changeHandler(inputName, nextItems);
  };

  return (
    <div className="mb-4">
      {label && <Form.Label>{label}</Form.Label>}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items?.map((item, i) => (
                <Item
                  key={`item-${i}`}
                  index={i}
                  item={item}
                  handleRemoveItem={handleRemoveItem}
                  handleInputChange={handleInputChange}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mt-3">
        <Button
          type="button"
          variant="outline-primary"
          className="w-100"
          onClick={handleAddItem}
          size="sm"
        >
          Add Year / Semester
        </Button>
      </div>
    </div>
  );
};

export default CourseDetailsComponent;

const Item = ({ index, item, handleRemoveItem, handleInputChange }) => (
  <Draggable draggableId={`item-${index}`} index={index}>
    {(provided) => (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="mb-3"
      >
        <Card.Header className="d-flex align-items-center">
          <Icon.List />

          <CloseButton
            className="ms-auto"
            onClick={() => handleRemoveItem(index)}
          />
        </Card.Header>

        <Card.Body>
          <FormInput
            label="Year / Semester"
            type="text"
            name="title"
            value={item.title}
            changeHandler={(e) => handleInputChange(index, e)}
          />
          <FormTextarea
            label="Modules"
            name="modules"
            rows={3}
            value={item.modules}
            changeHandler={(e) => handleInputChange(index, e)}
          />
        </Card.Body>
      </Card>
    )}
  </Draggable>
);
