import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Card, Row, Form, Col, Button } from 'react-bootstrap';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { courseFetchAll } from '../actions/courseActions';
import Layout from '../components/Layout';
import ListTopBar from '../components/ListTopBar';
import PaginationBlock from '../components/PaginationBlock';
import useQuery from '../hooks/useQuery';
import { Routes } from '../json/routes';
import useForm from '../hooks/useForm';
import { universityFetchAllFlat } from '../actions/universityActions';
import { subjectFetchAllFlat } from '../actions/subjectActions';
import { intakeFetchAllFlat } from '../actions/intakeActions';
import { capitalize, htmlDecode } from '../helpers/helpers';

const CoursesPage = () => {
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.courseReducer);
  const { universityListFlat } = useSelector(
    (state) => state.universityReducer,
  );
  const { subjectListFlat } = useSelector((state) => state.subjectReducer);
  const { intakeListFlat } = useSelector((state) => state.intakeReducer);

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const page = query.get('page');
  const university = query.get('university');
  const title = query.get('title');
  const sort = query.get('sort');
  const intake = query.get('intake');
  const subject = query.get('subject');

  const [isLoading, setIsLoading] = useState(true);
  const { values, handleStateChange, handleInputChange } = useForm({
    title: title || '',
    intake: intake || '',
    subject: subject || '',
    sort: sort || 'createdAt',
    page: page || 1,
    university: university || '',
  });

  const prepareQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    const params = {
      title: nextData.title,
      sort: nextData.sort,
      university: nextData.university,
      intake: nextData.intake,
      subject: nextData.subject,
      page: nextData.page,
    };

    return params;
  };

  const prepareSearchQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    nextData.title ? query.set('title', nextData.title) : query.delete('title');
    nextData.sort ? query.set('sort', nextData.sort) : query.delete('sort');
    nextData.university
      ? query.set('university', nextData.university)
      : query.delete('university');
    nextData.intake
      ? query.set('intake', nextData.intake)
      : query.delete('intake');
    nextData.subject
      ? query.set('subject', nextData.subject)
      : query.delete('subject');
    nextData.page ? query.set('page', nextData.page) : query.delete('page');

    const searchParams = query.toString();

    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    });
  };

  const handlePage = (p) => {
    setIsLoading(true);

    handleStateChange('page', p);

    const params = prepareQuery(values, { page: p });

    dispatch(courseFetchAll(params));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setIsLoading(true);

    prepareSearchQuery(values, { page: 1 });

    const params = prepareQuery(values, { page: 1 });

    dispatch(courseFetchAll(params));
  };

  useEffect(() => {
    dispatch(universityFetchAllFlat());
    dispatch(subjectFetchAllFlat());
    dispatch(intakeFetchAllFlat());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      ...(page && { page }),
      ...(title && { title }),
      ...(sort && { sort }),
      ...(university && { university }),
      ...(intake && { intake }),
      ...(subject && { subject }),
    };

    dispatch(courseFetchAll(params));
  }, [dispatch, university, page, title, sort, intake, subject]);

  useEffect(() => {
    if (courseList?.items) setIsLoading(false);
  }, [courseList]);

  return (
    <Layout>
      <ListTopBar
        title="Courses"
        newItemLink={
          university
            ? `${Routes.Course.new}?university=${university}`
            : Routes.Course.new
        }
        total={courseList.total}
      />
      <ListSearchbar
        values={values}
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        universityListFlat={universityListFlat}
        subjectListFlat={subjectListFlat}
        intakeListFlat={intakeListFlat}
      />

      {isLoading ? (
        'Loading...'
      ) : (
        <ListBlock courseList={courseList} handlePage={handlePage} />
      )}
    </Layout>
  );
};

export default CoursesPage;

const ListSearchbar = ({
  handleSearch,
  values,
  handleInputChange,
  universityListFlat,
  subjectListFlat,
  intakeListFlat,
}) => (
  <Card body className="mt-3">
    <form onSubmit={handleSearch}>
      <Row>
        <Form.Group as={Col} md={2}>
          <Form.Label>Title</Form.Label>
          <Form.Control
            value={values.title}
            name="title"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>University</Form.Label>
          <Form.Select
            onChange={handleInputChange}
            value={values.university || ''}
            name="university"
          >
            <option value="">Choose one...</option>
            {universityListFlat?.map((option) => (
              <option key={option.id} value={option.id}>
                {htmlDecode(option.title)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Intake</Form.Label>
          <Form.Select
            onChange={handleInputChange}
            value={values.intake}
            name="intake"
          >
            <option value="">Choose one...</option>
            {intakeListFlat?.map((option) => (
              <option key={option.id} value={option.id}>
                {`${capitalize(option.month)} ${option.year || ''}`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Subject</Form.Label>
          <Form.Select
            onChange={handleInputChange}
            value={values.subject}
            name="subject"
          >
            <option value="">Choose one...</option>
            {subjectListFlat?.map((option) => (
              <option key={option.id} value={option.id}>
                {htmlDecode(option.title)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} md={2}>
          <Form.Label>Order by</Form.Label>

          <Form.Select
            value={values.sort}
            name="sort"
            onChange={handleInputChange}
          >
            <option value="createdAt">Created</option>
            <option value="title">Title</option>
          </Form.Select>
        </Form.Group>

        <Col md="auto" className="mt-auto">
          <Button variant="primary" onClick={handleSearch} type="submit">
            Filter
          </Button>
        </Col>
      </Row>
    </form>
  </Card>
);

const ListBlock = ({ courseList, handlePage }) => (
  <>
    <ListGroup className="mt-3">
      {courseList.items?.map((item) => (
        <ListGroup.Item
          key={item.id}
          as={NavLink}
          to={`${Routes.Course.item}${item.id}`}
          variant={item.isActive ? '' : 'secondary'}
          action
        >
          {item.university && (
            <div className="fs-6 fw-light text-muted">
              {htmlDecode(item.university)}
            </div>
          )}

          <div className="d-flex align-items-center">
            <div>
              <span className="fs-5 fw-bold text-primary">
                {htmlDecode(item.title)}
              </span>

              {!item.isActive && (
                <div className="fs-6 fw-light text-muted">Not Active</div>
              )}
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>

    <PaginationBlock
      pageHandler={handlePage}
      currentPage={courseList.page}
      total={courseList.total}
    />
  </>
);
