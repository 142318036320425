import axios from 'axios';
import { setAuthToken } from './auth';
import store from '../store/store';
import STORAGE from './storage';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_ORIGIN,
});

$api.interceptors.request.use((config) => {
  const accessToken = STORAGE.getItem('jwtToken');

  const nextConfig = { ...config };

  nextConfig.headers.Authorization = `Bearer ${accessToken}`;

  return nextConfig;
});

const handleLogout = () => {
  STORAGE.clear();

  return store.dispatch({
    type: 'USER_LOGOUT',
  });
};

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
  subscribers.forEach((callback) => callback(accessToken));
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

$api.interceptors.response.use(
  (response) => response,
  (err) => {
    const errorResponse = err.response;

    if (errorResponse.status !== 401) throw err;

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      $api
        .get(`auth/token`)
        .then((response) => {
          const { accessToken } = response.data;

          STORAGE.setItem('jwtToken', accessToken);

          setAuthToken(accessToken);

          onAccessTokenFetched(accessToken);
          isAlreadyFetchingAccessToken = false;
        })
        .catch(() => handleLogout());
    }

    return retryOriginalRequest;
  },
);

export default $api;
