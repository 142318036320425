import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import JoditEditor from '../JoditEditor';
import { htmlDecode } from '../../helpers/helpers';

const FormEditor = ({ label, name, changeHandler, defaultValue }) =>
  useMemo(
    () => (
      <Form.Group className="mb-4">
        <Form.Label>{label}</Form.Label>
        <JoditEditor
          name={name}
          defaultValue={htmlDecode(defaultValue)}
          changeHandler={changeHandler}
        />
      </Form.Group>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue],
  );

export default FormEditor;
