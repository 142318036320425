import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NotFoundPage = () => (
  <Container>
    <Row className="justify-content-md-center my-5">
      <Col md={4}>
        <img
          src="/logo-dark.svg"
          alt="logo"
          width="180"
          className="mb-3 d-block mx-auto"
        />
        <h1 className="h5 mx-aut mb-5 text-center" style={{ color: '#222B64' }}>
          Page Not Found
        </h1>

        <div className="mx-aut text-center">
          <NavLink to="/">Back to Homepage</NavLink>
        </div>
      </Col>
    </Row>
  </Container>
);

export default NotFoundPage;
