import React from 'react';
import Navbar from '../Navbar';
import SidebarNav from '../SidebarNav';
import Toast from '../Toast';
import styles from './Layout.module.scss';

const Layout = ({ children }) => (
  <main>
    <SidebarNav />
    <div className={styles.content}>
      <Navbar />
      {children}
    </div>
    <Toast />
  </main>
);

export default Layout;
