import React from 'react';
import { Card, Button, Form, CloseButton, Row } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Icon from 'react-bootstrap-icons';
import { htmlDecode } from '../../helpers/helpers';

const FormKeyValue = ({
  label,
  inputName,
  items,
  changeHandler,
  keyLabel,
  valueLabel,
  addLabel,
}) => {
  const handleInputChange = (id, e) => {
    const { name, value } = e.target;

    const nextItems = [...items];
    nextItems[id][name] = value;

    changeHandler(inputName, nextItems);
  };

  const handleAddItem = () => {
    const newItem = { title: '', value: '' };
    const nextState = [...items, newItem];

    changeHandler(inputName, nextState);
  };

  const handleRemoveItem = (id) => {
    const nextItems = [...items];
    nextItems.splice(id, 1);

    changeHandler(inputName, nextItems);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const nextItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    changeHandler(inputName, nextItems);
  };

  return (
    <div className="mb-4">
      <Form.Label>{label}</Form.Label>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items?.map((item, i) => (
                <Item
                  key={`item-${i}`}
                  item={item}
                  index={i}
                  keyLabel={keyLabel}
                  valueLabel={valueLabel}
                  handleRemoveItem={handleRemoveItem}
                  handleInputChange={handleInputChange}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mt-3">
        <Button
          type="button"
          variant="outline-primary"
          className="w-100"
          onClick={handleAddItem}
          size="sm"
        >
          {addLabel || 'Add Item'}
        </Button>
      </div>
    </div>
  );
};

const Item = ({
  item,
  index,
  handleRemoveItem,
  handleInputChange,
  keyLabel,
  valueLabel,
}) => (
  <Draggable draggableId={`item-${index}`} index={index}>
    {(provided) => (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="mb-3"
      >
        <Card.Header className="d-flex align-items-center">
          <Icon.List />

          <CloseButton
            className="ms-auto"
            onClick={() => handleRemoveItem(index)}
          />
        </Card.Header>

        <Card.Body>
          <Row md={2}>
            <Form.Group className="px-1">
              <Form.Label>{keyLabel || 'Title'}</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => handleInputChange(index, e)}
                value={htmlDecode(item.title)}
                name="title"
              />
            </Form.Group>

            <Form.Group className="px-1">
              <Form.Label>{valueLabel || 'Value'}</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => handleInputChange(index, e)}
                value={htmlDecode(item.value)}
                name="value"
              />
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
    )}
  </Draggable>
);

export default FormKeyValue;
