import SubjectService from '../services/subjectService';

import {
  SUBJECT_FETCH_ALL,
  SUBJECT_FETCH_ALL_FLAT,
  SUBJECT_FETCH_ONE,
  SUBJECT_CREATE,
  SUBJECT_UPDATE,
  SUBJECT_DELETE,
  SUBJECT_SET_ERRORS,
  SUBJECT_CLEAR_STATE,
} from './actionTypes';

export const subjectFetchAll = (query) => (dispatch) => {
  SubjectService.getAll(query)
    .then(({ data }) =>
      dispatch({
        type: SUBJECT_FETCH_ALL,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: SUBJECT_SET_ERRORS,
        payload: response,
      }),
    );
};

export const subjectFetchAllFlat = () => (dispatch) => {
  SubjectService.getAllFlat()
    .then(({ data }) =>
      dispatch({
        type: SUBJECT_FETCH_ALL_FLAT,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: SUBJECT_SET_ERRORS,
        payload: response,
      }),
    );
};

export const subjectFetchOne = (id) => (dispatch) => {
  SubjectService.getOne(id)
    .then(({ data }) =>
      dispatch({
        type: SUBJECT_FETCH_ONE,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: SUBJECT_SET_ERRORS,
        payload: response,
      }),
    );
};

export const subjectCreate = (newData, onSuccess) => (dispatch) => {
  SubjectService.create(newData)
    .then(({ data }) => {
      dispatch({
        type: SUBJECT_CREATE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: SUBJECT_SET_ERRORS,
        payload: response,
      }),
    );
};

export const subjectUpdate = (id, newData, onSuccess) => (dispatch) => {
  SubjectService.update(id, newData)
    .then(({ data }) => {
      dispatch({
        type: SUBJECT_UPDATE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: SUBJECT_SET_ERRORS,
        payload: response,
      }),
    );
};

export const subjectDeleteOne = (id, onSuccess) => (dispatch) => {
  SubjectService.remove(id)
    .then(({ data }) => {
      dispatch({
        type: SUBJECT_DELETE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch(({ response }) =>
      dispatch({
        type: SUBJECT_SET_ERRORS,
        payload: response,
      }),
    );
};

export const subjectClearState = () => (dispatch) => {
  dispatch({
    type: SUBJECT_CLEAR_STATE,
  });
};
