import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import styles from './SidebarNav.module.scss';

const navList = [
  {
    id: 1,
    title: 'Universites',
    link: '/universities',
    icon: <Icon.Bank2 />,
  },
  {
    id: 2,
    title: 'Courses',
    link: '/courses',
    icon: <Icon.Award />,
  },
  {
    id: 3,
    title: 'Subjects',
    link: '/subjects',
    icon: <Icon.Bookmark />,
  },
  {
    id: 4,
    title: 'Intakes',
    link: '/intakes',
    icon: <Icon.Calendar3 />,
  },
  {
    id: 5,
    title: 'Locations',
    link: '/locations',
    icon: <Icon.PinMapFill />,
  },
  {
    id: 6,
    title: 'Users',
    link: '/users',
    allow: 'ADMIN',
    icon: <Icon.PeopleFill />,
  },
  {
    id: 7,
    title: 'Course Stats',
    link: '/courses-user-stats/',
    allow: 'ADMIN',
    icon: <Icon.BarChartFill />,
  },
];

const SidebarNav = () => {
  const { role } = useSelector((state) => state.authReducer.user);

  return (
    <nav className={styles.wrapper}>
      <img src="/logo-light.svg" alt="logo" className={styles.logo} />

      <div className={styles.linkWrapper}>
        {navList.map((item) => (
          <React.Fragment key={item.id}>
            {(!item.allow || (item.allow && role === item.allow)) && (
              <Nav.Item className={styles.navItem}>
                <NavLink to={item.link || '/'} className={styles.navLink}>
                  {item.icon && item.icon}
                  {item.title}
                </NavLink>
              </Nav.Item>
            )}
          </React.Fragment>
        ))}

        <hr />

        <Nav.Item className={styles.navItem}>
          <a
            href="https://docs.google.com/document/d/1ZzbwhN4yJrfnoMGpg3KTJIVvHcj5jygOldsf1_H8dzk/edit"
            target="_blank"
            className={styles.navLink}
            rel="noreferrer"
          >
            <Icon.InfoCircle />
            Instructions
          </a>
        </Nav.Item>
      </div>
    </nav>
  );
};

export default SidebarNav;
