import {
  UNIVERSITY_FETCH_ALL,
  UNIVERSITY_FETCH_ALL_FLAT,
  UNIVERSITY_FETCH_ONE,
  UNIVERSITY_CREATE,
  UNIVERSITY_UPDATE,
  UNIVERSITY_DELETE,
  UNIVERSITY_SET_ERRORS,
  UNIVERSITY_CLEAR_STATE,
} from '../actions/actionTypes';

const initialState = {
  universityList: {},
  universityListFlat: [],
  university: {},
  errors: null,
};

const universityReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNIVERSITY_FETCH_ALL:
      return {
        ...state,
        universityList: { ...action.payload },
        errors: null,
      };

    case UNIVERSITY_FETCH_ALL_FLAT:
      return {
        ...state,
        universityListFlat: [...action.payload],
        errors: null,
      };

    case UNIVERSITY_FETCH_ONE:
      return {
        ...state,
        university: { ...action.payload },
        errors: null,
      };

    case UNIVERSITY_CREATE:
      return {
        ...state,
        university: { ...action.payload },
        errors: null,
      };

    case UNIVERSITY_UPDATE:
      return {
        ...state,
        university: { ...action.payload },
        errors: null,
      };

    case UNIVERSITY_DELETE:
      return {
        ...state,
        university: { ...action.payload },
        errors: null,
      };

    case UNIVERSITY_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case UNIVERSITY_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default universityReducer;
