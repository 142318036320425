import React, { useRef, useState } from 'react';
import {
  Col,
  Card,
  Button,
  Form,
  CloseButton,
  Row,
  Image,
  Alert,
} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import * as Icon from 'react-bootstrap-icons';
import isImage from 'is-image';
import styles from './FormMediaComponent.module.scss';
import { htmlDecode } from '../../helpers/helpers';

const FormMediaComponent = ({
  label,
  valuesName,
  filesName,
  values,
  files,
  changeHandler,
  accept,
  hasError,
}) => {
  const inputRef = useRef();
  const [previews, setPreviews] = useState({});

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onFilesDragEnd = (result) => {
    if (!result.destination) return;

    const nextItems = reorder(
      values,
      result.source.index,
      result.destination.index,
    );

    changeHandler(valuesName, nextItems);
  };

  const handleImgChange = (e) => {
    const uploadedFiles = e.target.files;

    let nextValues = [...values];
    let nextFiles = [...files];
    let nextPreviews = { ...previews };

    Object.keys(uploadedFiles).map((f) => {
      const reader = new FileReader();
      const file = uploadedFiles[f];
      const id = uuid();

      nextValues = [
        ...nextValues,
        { preview: id, fileName: file.name, caption: '' },
      ];
      nextFiles = [...nextFiles, { file }];

      if (isImage(file.name)) {
        reader.onloadend = () => {
          nextPreviews = {
            ...nextPreviews,
            [id]: { url: reader.result, name: file.name },
          };
          setPreviews(nextPreviews);
        };
        reader.readAsDataURL(file);
      }

      return uploadedFiles;
    });

    changeHandler(valuesName, nextValues);
    changeHandler(filesName, nextFiles);

    inputRef.current.value = '';
  };

  const handleImgRemove = (fileName) => {
    let nextValues = [...values];
    nextValues = nextValues.filter((file) => file !== fileName);
    changeHandler(valuesName, nextValues);

    let nextFiles = [...files];
    nextFiles = nextFiles.filter(
      (file) => file.file.name !== fileName.fileName,
    );
    changeHandler(filesName, nextFiles);
  };

  const handleChangeCaption = (index, e) => {
    const { name, value } = e.target;

    const nextValues = [...values];

    nextValues[index][name] = value;

    changeHandler(valuesName, nextValues);
  };

  return (
    <div className="mb-3">
      <Form.Label>{label}</Form.Label>

      <div className="mb-3">
        <DragDropContext onDragEnd={onFilesDragEnd}>
          <Droppable droppableId="droppableFiles">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {values.map((file, i) => (
                  <FileItem
                    key={`file-${i}`}
                    index={i}
                    file={file}
                    previews={previews}
                    handleImgRemove={handleImgRemove}
                    handleChangeCaption={handleChangeCaption}
                    hasError={hasError}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {hasError && (
        <Alert variant="danger" className="my-3">
          {hasError}
        </Alert>
      )}

      <Button
        as={Form.Label}
        variant="outline-primary"
        className="w-100"
        size="sm"
      >
        <input
          className={styles.input}
          ref={inputRef}
          type="file"
          onChange={handleImgChange}
          multiple
          accept={accept}
        />
        <span>Upload File</span>
      </Button>
    </div>
  );
};

const FileItem = ({
  file,
  index,
  handleImgRemove,
  handleChangeCaption,
  previews,
  hasError,
}) => (
  <Draggable draggableId={`item-${index}`} index={index}>
    {(provided) => (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="mb-3"
        border={(file.preview && 'primary') || (hasError && 'danger')}
      >
        <Card.Header className="d-flex align-items-center">
          <Icon.List />

          <CloseButton
            className="ms-auto"
            onClick={() => handleImgRemove(file)}
          />
        </Card.Header>

        <Card.Body>
          <Row>
            <Col md={3}>
              {previews[file.preview] || isImage(file.url) ? (
                <Image
                  src={
                    file.preview
                      ? previews[file.preview].url
                      : `${process.env.REACT_APP_API_PUBLIC}${file.url}`
                  }
                  thumbnail
                />
              ) : (
                <Icon.FileEarmark size={40} />
              )}
            </Col>

            <Col md={9}>
              {isImage(file.url) || file.preview ? (
                <div className="mb-2">{file.fileName || file.url}</div>
              ) : (
                <a
                  href={`${process.env.REACT_APP_API_PUBLIC}${file.url}`}
                  target="_blank"
                  rel="noreferrer"
                  className="d-block mb-2"
                >
                  {file.fileName || file.url}
                </a>
              )}

              <Form.Label>Caption</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => handleChangeCaption(index, e)}
                value={htmlDecode(file.caption)}
                name="caption"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )}
  </Draggable>
);

export default FormMediaComponent;
