import CourseService from '../services/courseService';

import {
  COURSE_FETCH_ALL,
  COURSE_FETCH_ALL_FLAT,
  COURSE_FETCH_ONE,
  COURSE_FETCH_USER_STATS,
  COURSE_CREATE,
  COURSE_UPDATE,
  COURSE_DELETE,
  COURSE_SET_ERRORS,
  COURSE_CLEAR_STATE,
} from './actionTypes';

export const courseFetchAll = (query) => (dispatch) => {
  CourseService.getAll(query)
    .then(({ data }) =>
      dispatch({
        type: COURSE_FETCH_ALL,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const courseFetchAllFlat = (query) => (dispatch) => {
  CourseService.getAllFlat(query)
    .then(({ data }) =>
      dispatch({
        type: COURSE_FETCH_ALL_FLAT,
        payload: data,
      }),
    )
    .catch(({ response }) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: response,
      }),
    );
};

export const courseFetchOne = (id) => (dispatch) => {
  CourseService.getOne(id)
    .then(({ data }) =>
      dispatch({
        type: COURSE_FETCH_ONE,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const courseFetchUserStats = (query) => (dispatch) => {
  CourseService.getUserStats(query)
    .then(({ data }) =>
      dispatch({
        type: COURSE_FETCH_USER_STATS,
        payload: data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const courseCreate = (data, onSuccess) => (dispatch) => {
  CourseService.create(data)
    .then((res) => {
      dispatch({
        type: COURSE_CREATE,
        payload: res.data,
      });

      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const courseUpdate = (id, data, onSuccess) => (dispatch) => {
  CourseService.update(id, data)
    .then((res) => {
      dispatch({
        type: COURSE_UPDATE,
        payload: res.data,
      });

      if (onSuccess) onSuccess(res.data);
    })
    .catch((err) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const courseDeleteOne = (id, onSuccess) => (dispatch) => {
  CourseService.remove(id)
    .then(({ data }) => {
      dispatch({
        type: COURSE_DELETE,
        payload: data,
      });

      if (onSuccess) onSuccess(data);
    })
    .catch((err) =>
      dispatch({
        type: COURSE_SET_ERRORS,
        payload: err.response,
      }),
    );
};

export const courseClearState = () => (dispatch) => {
  dispatch({
    type: COURSE_CLEAR_STATE,
  });
};
