import {
  LOCATION_FETCH_ALL,
  LOCATION_FETCH_ALL_FLAT,
  LOCATION_FETCH_ONE,
  LOCATION_CREATE,
  LOCATION_UPDATE,
  LOCATION_DELETE,
  LOCATION_SET_ERRORS,
  LOCATION_CLEAR_STATE,
} from '../actions/actionTypes';

const initialState = {
  locationList: {},
  locationListFlat: [],
  location: {},
  errors: null,
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_FETCH_ALL:
      return {
        ...state,
        locationList: { ...action.payload },
        errors: null,
      };

    case LOCATION_FETCH_ALL_FLAT:
      return {
        ...state,
        locationListFlat: [...action.payload],
        errors: null,
      };

    case LOCATION_FETCH_ONE:
      return {
        ...state,
        location: { ...action.payload },
        errors: null,
      };

    case LOCATION_CREATE:
      return {
        ...state,
        location: { ...action.payload },
        errors: null,
      };

    case LOCATION_UPDATE:
      return {
        ...state,
        location: { ...action.payload },
        errors: null,
      };

    case LOCATION_DELETE:
      return {
        ...state,
        location: { ...action.payload },
        errors: null,
      };

    case LOCATION_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case LOCATION_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default locationReducer;
