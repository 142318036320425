import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from 'react-bootstrap';
import uuid from 'react-uuid';
import useForm from '../hooks/useForm';
import { loginUser } from '../actions/authActions';
import FormInput from '../components/FormInput';

const initialFieldValues = {
  email: '',
  password: '',
};

const LoginPage = () => {
  const dispatch = useDispatch();
  const loginError = useSelector((state) => state.authReducer.errors);

  const [inputErrors, setInputErrors] = useState();

  const { values, handleInputChange } = useForm(initialFieldValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(values));
  };

  useEffect(() => {
    if (!loginError) return;

    const nextInputErrors = loginError.errors?.reduce((acc, err) => {
      acc[err.param] = err;
      return acc;
    }, {});

    setInputErrors(nextInputErrors);
  }, [loginError]);

  const handleChange = (e) => {
    const { name } = e.target;

    if (inputErrors && inputErrors[name]) {
      setInputErrors((initState) => ({
        ...initState,
        [name]: {},
      }));
    }

    handleInputChange(e);
  };

  return (
    <Container>
      <Row className="justify-content-md-center my-5">
        <Col md={4}>
          <img
            src="/logo-dark.svg"
            alt="logo"
            width="180"
            className="mb-3 d-block mx-auto"
          />
          <h1
            className="h5 mx-aut mb-5 text-center"
            style={{ color: '#222B64' }}
          >
            Institution & Course Database APP
          </h1>

          <Card>
            <Card.Header>Login</Card.Header>

            <Card.Body>
              {loginError && loginError.status === 400 && (
                <Alert variant="danger">
                  {loginError.errors.map((err) => (
                    <div key={uuid()}>{err.msg}</div>
                  ))}
                </Alert>
              )}

              <Form noValidate onSubmit={handleSubmit}>
                <FormInput
                  label="Email"
                  type="email"
                  name="email"
                  value={values.email}
                  changeHandler={handleChange}
                  hasError={inputErrors?.email?.msg}
                />

                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  value={values.password}
                  changeHandler={handleChange}
                  hasError={inputErrors?.password?.msg}
                />

                <div className="mt-3">
                  <Button variant="primary" type="submit">
                    Login
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
