import React from 'react';
import { Form } from 'react-bootstrap';

const FormRadioSelect = ({
  label,
  name,
  value = [],
  options = [],
  changeHandler,
  hasError,
}) => (
  <Form.Group className="mb-4">
    <Form.Label>{label}</Form.Label>

    <div>
      {options.map((option) => (
        <Form.Check
          key={option}
          inline
          label={option}
          name={name}
          type="radio"
          checked={value === option}
          onChange={() => changeHandler(name, option)}
          id={`${name}-${option}`}
          isInvalid={Boolean(hasError)}
        />
      ))}
    </div>
    <Form.Control.Feedback type="invalid">{hasError}</Form.Control.Feedback>
  </Form.Group>
);
export default FormRadioSelect;
