import {
  USER_LOGIN,
  USER_LOGOUT,
  GET_ERRORS,
  SET_CURRENT_USER,
} from '../actions/actionTypes';

const isEmpty = require('is-empty');

const initialState = {
  isAuthenticated: false,
  user: {},
  errors: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };

    case USER_LOGOUT:
      return { ...initialState };

    case GET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    default:
      return state;
  }
};

export default auth;
