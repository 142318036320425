import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import useQuery from '../../hooks/useQuery';

const perPage = 10;

const PaginationBlock = ({ currentPage, total, pageHandler }) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const items = [];
  const totalPages = Math.ceil(total / perPage);

  const handlePageChange = (page) => {
    query.set('page', page);

    const searchParams = query.toString();

    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    });

    pageHandler(page);
  };

  const getFirst = () => {
    let first = 1;

    if (currentPage > 3) first = currentPage - 2;

    if (currentPage > totalPages - 3) first = totalPages - 4;

    if (totalPages < 6) first = 1;

    return first;
  };

  const getLast = () => {
    let last = getFirst() + 4;

    if (last === totalPages || totalPages < 5) last = totalPages;

    return last;
  };

  if (currentPage > 3 && totalPages > 5) {
    items.push(
      <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
        1
      </Pagination.Item>,
    );

    items.push(<Pagination.Ellipsis key="start" disabled />);
  }

  for (let number = getFirst(); number <= getLast(); number += 1) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>,
    );
  }

  if (totalPages > 4 && currentPage < totalPages - 2) {
    items.push(<Pagination.Ellipsis key="end" disabled />);

    items.push(
      <Pagination.Item
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>,
    );
  }

  return (
    <>
      {totalPages > 1 && (
        <div className="mt-3">
          <Pagination>{items}</Pagination>
        </div>
      )}
    </>
  );
};

PaginationBlock.propTypes = {
  currentPage: PropTypes.number,
  total: PropTypes.number,
  pageHandler: PropTypes.func,
};

PaginationBlock.defaultProps = {
  currentPage: 1,
  total: 0,
  pageHandler: () => {},
};

export default PaginationBlock;
