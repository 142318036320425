import { UI_IS_LOADING, UI_SET_TOAST, CLEAR_STATE } from './actionTypes';

export const setIsLoading = (loadingState) => (dispatch) => {
  dispatch({
    type: UI_IS_LOADING,
    payload: loadingState,
  });
};

export const setToast = (toast) => (dispatch) => {
  dispatch({
    type: UI_SET_TOAST,
    payload: toast,
  });
};

export const clearState = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};
