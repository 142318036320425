import React from 'react';
import { Form } from 'react-bootstrap';
import { htmlDecode } from '../../helpers/helpers';

const FormInput = ({
  label,
  type,
  name,
  inputId,
  value,
  changeHandler,
  focusHandler,
  blurHandler,
  disabled,
  hasError,
}) => (
  <Form.Group className="mb-4" controlId={inputId}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type={type}
      onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
      value={htmlDecode(value)}
      name={name}
      isInvalid={Boolean(hasError)}
      disabled={Boolean(disabled)}
    />
    <Form.Control.Feedback type="invalid">{hasError}</Form.Control.Feedback>
  </Form.Group>
);

export default FormInput;
