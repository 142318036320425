import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { userFetchAll } from '../actions/userActions';
import Layout from '../components/Layout';
import PaginationBlock from '../components/PaginationBlock';
import useQuery from '../hooks/useQuery';
import useForm from '../hooks/useForm';
import { Routes } from '../json/routes';
import ListTopBar from '../components/ListTopBar';

const UsersPage = () => {
  const dispatch = useDispatch();

  const { userList } = useSelector((state) => state.userReducer);

  const query = useQuery();

  const page = query.get('page');

  const [isLoading, setIsLoading] = useState(true);
  const { values, handleStateChange } = useForm({
    page: page || 1,
  });

  const prepareQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    const params = {
      page: nextData.page,
    };

    return params;
  };

  const handlePage = (p) => {
    setIsLoading(true);

    handleStateChange('page', p);

    const params = prepareQuery(values, { page: p });

    dispatch(userFetchAll(params));
  };

  useEffect(() => {
    const params = {
      ...(page && { page }),
    };

    dispatch(userFetchAll(params));
  }, [dispatch, page]);

  useEffect(() => {
    if (userList?.items) setIsLoading(false);
  }, [userList]);

  return (
    <Layout>
      <ListTopBar
        title="Users"
        newItemLink={Routes.User.new}
        total={userList.total}
      />

      {isLoading ? (
        'Loading...'
      ) : (
        <ListBlock userList={userList} handlePage={handlePage} />
      )}
    </Layout>
  );
};

export default UsersPage;

const ListBlock = ({ userList, handlePage }) => (
  <>
    <ListGroup className="mt-3">
      {userList.items?.map((item) => (
        <ListGroup.Item
          key={item.id}
          as={NavLink}
          to={`${Routes.User.item}${item.id}`}
          action
        >
          <div className="d-flex align-items-center">
            <span className="fs-5 fw-bold text-primary">{item.name}</span>
          </div>
          <div className="fs-6 fw-light text-muted">
            {item.role.toLowerCase()}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>

    <PaginationBlock
      pageHandler={handlePage}
      currentPage={userList.page}
      total={userList.total}
    />
  </>
);
