import { combineReducers } from 'redux';

import authReducer from './authReducer';
import uiReducer from './uiReducer';
import universityReducer from './universityReducer';
import courseReducer from './courseReducer';
import subjectReducer from './subjectReducer';
import userReducer from './userReducer';
import intakeReducer from './intakeReducer';
import locationReducer from './locationReducer';

const reducers = combineReducers({
  authReducer,
  uiReducer,
  universityReducer,
  courseReducer,
  subjectReducer,
  userReducer,
  intakeReducer,
  locationReducer,
});

export default reducers;
