import {
  SUBJECT_FETCH_ALL,
  SUBJECT_FETCH_ALL_FLAT,
  SUBJECT_FETCH_ONE,
  SUBJECT_CREATE,
  SUBJECT_UPDATE,
  SUBJECT_DELETE,
  SUBJECT_SET_ERRORS,
  SUBJECT_CLEAR_STATE,
} from '../actions/actionTypes';

const initialState = {
  subjectList: {},
  subjectListFlat: [],
  subject: {},
  errors: null,
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBJECT_FETCH_ALL:
      return {
        ...state,
        subjectList: { ...action.payload },
        errors: null,
      };

    case SUBJECT_FETCH_ALL_FLAT:
      return {
        ...state,
        subjectListFlat: [...action.payload],
        errors: null,
      };

    case SUBJECT_FETCH_ONE:
      return {
        ...state,
        subject: { ...action.payload },
        errors: null,
      };

    case SUBJECT_CREATE:
      return {
        ...state,
        subject: { ...action.payload },
        errors: null,
      };

    case SUBJECT_UPDATE:
      return {
        ...state,
        subject: { ...action.payload },
        errors: null,
      };

    case SUBJECT_DELETE:
      return {
        ...state,
        subject: { ...action.payload },
        errors: null,
      };

    case SUBJECT_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case SUBJECT_CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default subjectReducer;
