import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Col, Row, Card, Form, Button } from 'react-bootstrap';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { courseFetchUserStats } from '../actions/courseActions';
import { userFetchAllFlat } from '../actions/userActions';
import Layout from '../components/Layout';
import PaginationBlock from '../components/PaginationBlock';
import useQuery from '../hooks/useQuery';
import { Routes } from '../json/routes';
import ListTopBar from '../components/ListTopBar';
import useForm from '../hooks/useForm';
import { htmlDecode } from '../helpers/helpers';

const CoursesUserStatsPage = () => {
  const dispatch = useDispatch();

  const { userStatsList } = useSelector((state) => state.courseReducer);
  const { userListFlat } = useSelector((state) => state.userReducer);

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const user = query.get('user');
  const page = query.get('page');

  const [isLoading, setIsLoading] = useState(true);
  const { values, handleStateChange, handleInputChange } = useForm({
    user: '',
    page: 1,
  });

  const prepareQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    const params = {
      user: nextData.user,
      page: nextData.page,
    };

    return params;
  };

  const prepareSearchQuery = (data, mergeData) => {
    const nextData = { ...data, ...mergeData };

    nextData.user ? query.set('user', nextData.user) : query.delete('user');
    nextData.page ? query.set('page', nextData.page) : query.delete('page');

    const searchParams = query.toString();

    history.push({
      pathname: location.pathname,
      search: `?${searchParams}`,
    });
  };

  const handlePage = (p) => {
    setIsLoading(true);

    handleStateChange('page', p);

    const params = prepareQuery(values, { page: p });

    dispatch(courseFetchUserStats(params));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setIsLoading(true);

    prepareSearchQuery(values, { page: 1 });

    const params = prepareQuery(values, { page: 1 });

    dispatch(courseFetchUserStats(params));
  };

  useEffect(() => {
    dispatch(userFetchAllFlat());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      ...(user && { user }),
      ...(page && { page }),
    };

    dispatch(courseFetchUserStats(params));
  }, [dispatch, user, page]);

  useEffect(() => {
    if (userStatsList?.items) setIsLoading(false);
  }, [userStatsList]);

  return (
    <Layout>
      <ListTopBar title="Course Stats" total={userStatsList.total} />

      <ListSearchbar
        values={values}
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        userList={userListFlat}
      />

      {isLoading ? (
        'Loading...'
      ) : (
        <ListBlock userStatsList={userStatsList} handlePage={handlePage} />
      )}
    </Layout>
  );
};

export default CoursesUserStatsPage;

const ListSearchbar = ({
  handleSearch,
  values,
  handleInputChange,
  userList,
}) => (
  <Card body className="mt-3">
    <form onSubmit={handleSearch}>
      <Row>
        <Form.Group as={Col} md={4}>
          <Form.Label>User</Form.Label>
          <Form.Select
            onChange={handleInputChange}
            value={values.user || ''}
            name="user"
          >
            <option value="">Choose one...</option>
            {userList?.map((option) => (
              <option key={option.id} value={option.id}>
                {htmlDecode(option.name)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Col md="auto" className="mt-auto">
          <Button variant="primary" onClick={handleSearch} type="submit">
            Filter
          </Button>
        </Col>
      </Row>
    </form>
  </Card>
);

const ListBlock = ({ userStatsList, handlePage }) => (
  <>
    <ListGroup className="mt-3">
      {userStatsList.items?.map((item) => (
        <ListGroup.Item
          key={item.id}
          as={NavLink}
          to={`${Routes.Course.item}${item.id}`}
          className="d-flex"
          action
        >
          <div>
            {item.university && (
              <div className="fs-6 fw-light text-muted">
                {htmlDecode(item.university)}
              </div>
            )}

            <div className="d-flex align-items-center">
              <span className="fs-5 fw-bold text-primary">
                {htmlDecode(item.title)}
              </span>
            </div>
          </div>

          {item.addedBy && (
            <div className="ms-auto fs-6 text-muted">
              Created by{' '}
              <span className="fw-bold text-primary">
                {' '}
                {htmlDecode(item.addedBy)}
              </span>
            </div>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>

    <PaginationBlock
      pageHandler={handlePage}
      currentPage={userStatsList.page}
      total={userStatsList.total}
    />
  </>
);
