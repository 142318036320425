export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const USER_FETCH_ALL = 'USER_FETCH_ALL';
export const USER_FETCH_ALL_FLAT = 'USER_FETCH_ALL_FLAT';
export const USER_FETCH_ONE = 'USER_FETCH_ONE';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';
export const USER_SET_ERRORS = 'USER_SET_ERRORS';
export const USER_CLEAR_STATE = 'USER_CLEAR_STATE';

export const UNIVERSITY_FETCH_ALL = 'UNIVERSITY_FETCH_ALL';
export const UNIVERSITY_FETCH_ALL_FLAT = 'UNIVERSITY_FETCH_ALL_FLAT';
export const UNIVERSITY_FETCH_ONE = 'UNIVERSITY_FETCH_ONE';
export const UNIVERSITY_CREATE = 'UNIVERSITY_CREATE';
export const UNIVERSITY_UPDATE = 'UNIVERSITY_UPDATE';
export const UNIVERSITY_DELETE = 'UNIVERSITY_DELETE';
export const UNIVERSITY_SET_ERRORS = 'UNIVERSITY_SET_ERRORS';
export const UNIVERSITY_CLEAR_STATE = 'UNIVERSITY_CLEAR_STATE';

export const COURSE_FETCH_ALL = 'COURSE_FETCH_ALL';
export const COURSE_FETCH_ALL_FLAT = 'COURSE_FETCH_ALL_FLAT';
export const COURSE_FETCH_ONE = 'COURSE_FETCH_ONE';
export const COURSE_FETCH_USER_STATS = 'COURSE_FETCH_USER_STATS';
export const COURSE_CREATE = 'COURSE_CREATE';
export const COURSE_UPDATE = 'COURSE_UPDATE';
export const COURSE_DELETE = 'COURSE_DELETE';
export const COURSE_SET_ERRORS = 'COURSE_SET_ERRORS';
export const COURSE_CLEAR_STATE = 'COURSE_CLEAR_STATE';

export const SUBJECT_FETCH_ALL = 'SUBJECT_FETCH_ALL';
export const SUBJECT_FETCH_ALL_FLAT = 'SUBJECT_FETCH_ALL_FLAT';
export const SUBJECT_FETCH_ONE = 'SUBJECT_FETCH_ONE';
export const SUBJECT_CREATE = 'SUBJECT_CREATE';
export const SUBJECT_UPDATE = 'SUBJECT_UPDATE';
export const SUBJECT_DELETE = 'SUBJECT_DELETE';
export const SUBJECT_SET_ERRORS = 'SUBJECT_SET_ERRORS';
export const SUBJECT_CLEAR_STATE = 'SUBJECT_CLEAR_STATE';

export const INTAKE_FETCH_ALL = 'INTAKE_FETCH_ALL';
export const INTAKE_FETCH_ALL_FLAT = 'INTAKE_FETCH_ALL_FLAT';
export const INTAKE_FETCH_ONE = 'INTAKE_FETCH_ONE';
export const INTAKE_CREATE = 'INTAKE_CREATE';
export const INTAKE_UPDATE = 'INTAKE_UPDATE';
export const INTAKE_DELETE = 'INTAKE_DELETE';
export const INTAKE_SET_ERRORS = 'INTAKE_SET_ERRORS';
export const INTAKE_CLEAR_STATE = 'INTAKE_CLEAR_STATE';

export const LOCATION_FETCH_ALL = 'LOCATION_FETCH_ALL';
export const LOCATION_FETCH_ALL_FLAT = 'LOCATION_FETCH_ALL_FLAT';
export const LOCATION_FETCH_ONE = 'LOCATION_FETCH_ONE';
export const LOCATION_CREATE = 'LOCATION_CREATE';
export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const LOCATION_DELETE = 'LOCATION_DELETE';
export const LOCATION_SET_ERRORS = 'LOCATIONE_SET_ERRORS';
export const LOCATION_CLEAR_STATE = 'LOCATION_CLEAR_STATE';

export const UI_IS_LOADING = 'UI_IS_LOADING';
export const UI_SET_TOAST = 'UI_SET_TOAST';

export const CLEAR_STATE = 'CLEAR_STATE';
