export const intakes = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const months = [
  { value: 'january', label: 'January' },
  { value: 'february', label: 'February' },
  { value: 'march', label: 'March' },
  { value: 'april', label: 'April' },
  { value: 'may', label: 'May' },
  { value: 'june', label: 'June' },
  { value: 'july', label: 'July' },
  { value: 'august', label: 'August' },
  { value: 'september', label: 'September' },
  { value: 'october', label: 'October' },
  { value: 'november', label: 'November' },
  { value: 'december', label: 'December' },
];

export const levels = [
  'Pre-sessional English',
  'Foundation',
  'International Year one',
  'Undergraduate',
  'Undergraduate with Placement year',
  'Undergraduate Top Up',
  'Pre-Masters',
  'Post Graduate',
  'Post Graduate with placement year',
  'Post Graduate Research',
];

export const universityLevels = ['University', 'Pathway'];

export const pathwayTypes = [
  'Navitas Pathways',
  'Kaplan Pathways',
  'Oxford International Pathways',
  'Study Group Pathways',
  'On Campus Pathways',
  'QA Pathway',
];

export const attendances = [
  'Full Time',
  'Part time',
  'Online/ Distant learning',
  'Blended learning',
];
